import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    mobileNumber: null,
    authToken: null,
    loading: false,
    otpLoading: false,
    vendorOutletId: null,
    roles: [],
    selectedOutlet: {},
  },
  reducers: {
    startLoader: (state) => {
      state.loading = true
    },
    stopLoader: (state) => {
      state.loading = false
    },
    startOtpLoader: (state) => {
      state.otpLoading = true
    },
    stopOtpLoader: (state) => {
      state.otpLoading = false
    },
    loginSuccessful: (state, action) => {
      state.mobileNumber = action.payload.mobileNumber
      state.authToken = action.payload.authToken
      state.vendorOutletId = action.payload.vendorOutletId
    },
    logout: (state) => {
      state.mobileNumber = null
      state.authToken = null
      state.roles = []
      state.selectedOutlet = {}
      state.vendorOutletId = null
      state.otpLoading=null
      state.loading=null
    },
    rolesSuccessful: (state, action) => {
      state.roles = action.payload.roles
    },
    outletSelected: (state, action) => {
      state.selectedOutlet = action.payload.outlet
      state.vendorOutletId = action.payload.vendorOutletId
    }
  },
})

// Action creators are generated for each case reducer function
export const { loginSuccessful, logout, startLoader, stopLoader, rolesSuccessful, outletSelected, startOtpLoader, stopOtpLoader} = loginSlice.actions

export default loginSlice.reducer