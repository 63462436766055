import React from "react";
import {
  Table,
  Descriptions,
  Button,
  DatePicker,
  Spin,
  Typography
} from "antd";
import * as moment from "moment";
import './FeedBackScreen.css'
import { Constants } from "../../config/constant";
const { Title } = Typography;
const FeedBackScreen = ({
  startDate,
  endDate,
  getFeedbacks,
  onRangeChange,
  isLoding,
  SFeedbacksDisplay,
  columnsForFeedbacks,
  FeedbacksDisplay,
}) => {
    const { RangePicker } = DatePicker
  return (
    <div className="feedback-container">
      <Title className="text-title" level={4}>
        {Constants.FEEDBACK_SELECTED_DATES}{" "}
      </Title>
      <div className="header">
        <div>
          <RangePicker
            defaultValue={[
              moment(startDate, "YYYY-MM-DD"),
              moment(endDate, "YYYY-MM-DD"),
            ]}
            format={"YYYY-MM-DD"}
            onChange={onRangeChange}
          />
          <Button
            onClick={() =>
              getFeedbacks(startDate, endDate)
            }
          >
            Go
          </Button>
        </div>
      </div>
      <div>
        {isLoding === true && <Spin size="large" className="spinner" />}
      </div>
      <Descriptions title="">
        <Descriptions.Item label="Feedback Count">
          {SFeedbacksDisplay.feedbacks_count}
        </Descriptions.Item>
        <Descriptions.Item label="Orders Count">
          {SFeedbacksDisplay.orders_count}
        </Descriptions.Item>
        <Descriptions.Item label="Complaints Count">
          {SFeedbacksDisplay.complaints_count}
        </Descriptions.Item>
        <Descriptions.Item label="Average Rating">
          {Math.round(SFeedbacksDisplay.avg_ratings * 100) / 100}
        </Descriptions.Item>
        <Descriptions.Item label="Start Date">
          {SFeedbacksDisplay.start_date}
        </Descriptions.Item>
        <Descriptions.Item label="End Date">
          {SFeedbacksDisplay.end_date}
        </Descriptions.Item>
        {[...Array(6)].map((obj, i) => (
          <Descriptions.Item label="">
            {i} Rating{" "}
            {SFeedbacksDisplay.ratings_count
              ? SFeedbacksDisplay.ratings_count[i]
                ? SFeedbacksDisplay.ratings_count[i]
                : 0
              : 0}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Table
        size="small"
        columns={columnsForFeedbacks}
        dataSource={FeedbacksDisplay}
        pagination={false}
      />
    </div>
  );
};
export default FeedBackScreen;
