import React from "react";
import { Collapse, Table,Switch } from "antd";
import './MenuItems.css';
import { Constants } from "../../config/constant";
import { useMediaQuery } from 'react-responsive';
const { Panel } = Collapse;

const MenuItemScreen = ({ columnsForMenuItems, groupedData }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const mobileViewCol= columnsForMenuItems.map((elem) => {
    if(elem.title === Constants.ITEM_NAME || elem.title === Constants.IN_STOCK) {
      return elem;
    }else{
      return null;
    }
  }).filter(Boolean);

  return (
    <Collapse accordion>
      {Object.entries(groupedData).map(([foodType, foods]) => (
        <Panel
          showArrow={false}
          className="menuPanel"
          header={
            <div id='menuPanelHeader'>
               <h3>{foodType.replace(/_/g, "  ")}</h3>
              <div id="textContainer">
                <h5>{Constants.IN_STOCK}</h5>
                <h3 id="itemLength">{foods.filter((a) => a.is_active).length}</h3>
                <Switch />
              </div>
            </div>
          }
          key={foodType}
        >
          <Table
            size="small"
            columns={isTabletOrMobile ? mobileViewCol :columnsForMenuItems}
            dataSource={foods}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

export default MenuItemScreen;
