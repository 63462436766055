import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { createBrowserHistory } from "history";
import PageRoutes from './pageRoutes';
import Login from '../login/login';
import SideBar from '../components/sideBar/sideBar';
import OrdersByDate from '../components/ordersByDate/ordersByDate';
import { useSelector } from 'react-redux';
import OrderDetails from '../components/orderDetails/orderDetails';
import SelectOutlet from '../components/selectOutlet/selectOutlet';
import Example from '../components/example/example';
import Settings from '../components/settings/settings';
import MenuItemsViewModel from '../viewModels/MenuItemViewModel';
import OutletDetailsViewModel from '../viewModels/OutletDetailsViewModel';
import FeedBackViewModel from '../viewModels/FeedbackViewModel';

const history = createBrowserHistory();

const AppRoutes = (props) => {

    let routes = <Switch>
        <Route path={PageRoutes.login}>
            <Login />
        </Route>
        <Route
            path={PageRoutes.orderDetailsVendorUnAuth}
        // component={() =>
        //     <SideBar componentToRender={() => <OrderDetails origin="unAuthVenodr" />}
        //     />}
        >
            <OrderDetails origin="unAuthVendor" />
        </Route>
        <Route
            path={PageRoutes.orderDetailsPassengerUnAuth}
        // component={() =>
        //     <SideBar componentToRender={() => <OrderDetails origin="unAuthPassenger" />}
        //     />}
        >
            <OrderDetails origin="unAuthPassenger" />
        </Route>
        <Route path='/'>
            <Login />
        </Route>
    </Switch>
    const authToken = useSelector((state) => state.login.authToken)
    const vendorOutletId = useSelector((state) => state.login.vendorOutletId)

    if (authToken && !vendorOutletId) {
        routes = <Switch>
            <Route
                path={PageRoutes.orderDetailsVendorUnAuth}
            // component={() =>
            //     <SideBar componentToRender={() => <OrderDetails origin="unAuthVenodr" />}
            //     />}
            >
                <OrderDetails origin="unAuthVendor" />
            </Route>
            <Route
                path={PageRoutes.orderDetailsPassengerUnAuth}
            // component={() =>
            //     <SideBar componentToRender={() => <OrderDetails origin="unAuthPassenger" />}
            //     />}
            >
                <OrderDetails origin="unAuthPassenger" />
            </Route>
            <Route
                path={PageRoutes.outletSelection}
                component={() =>
                    <SelectOutlet authToken={authToken} />}
            />
            <Route
                path='/'
                component={() =>
                    <SelectOutlet authToken={authToken} />}
            />
        </Switch>
    } else if (authToken && vendorOutletId) {
        routes = <Switch>
            <Route path={PageRoutes.home} component={SideBar} />
            <Route
                path={PageRoutes.ordersByDate}
                component={() =>
                    <SideBar componentToRender={() => <OrdersByDate />}
                    />}
            />
            <Route
                path={PageRoutes.orderDetails}
                component={() =>
                    <SideBar componentToRender={() => <OrderDetails />}
                    />}
            />
            <Route
                path={PageRoutes.example}
                component={Example}
            />
            <Route
                path={PageRoutes.orderDetailsVendorUnAuth}
            // component={() =>
            //     <SideBar componentToRender={() => <OrderDetails origin="unAuthVenodr" />}
            //     />}
            >
                <OrderDetails origin="unAuthVendor" />
            </Route>
            <Route
                path={PageRoutes.orderDetailsPassengerUnAuth}
            // component={() =>
            //     <SideBar componentToRender={() => <OrderDetails origin="unAuthPassenger" />}
            //     />}
            >
                <OrderDetails origin="unAuthPassenger" />
            </Route>
            <Route
                path={PageRoutes.settings}
                component={() =>
                    <SideBar componentToRender={() => <Settings />}
                    />}
            />
             <Route
                path={PageRoutes.outletDetails}
                component={() =>
                    <SideBar componentToRender={() => <OutletDetailsViewModel />}
                    />}
            />
            <Route
                path={PageRoutes.menuItems}
                component={() =>
                    <SideBar componentToRender={() => <MenuItemsViewModel />}
                    />}
            />
             <Route
                path={PageRoutes.feedback}
                component={() =>
                    <SideBar componentToRender={() => <FeedBackViewModel />}
                    />}
            />
            <Route
                path={PageRoutes.outletSelection}
                component={() =>
                    <SelectOutlet authToken={authToken} />}
            />
            <Route
                path='/'
                component={SideBar}
            />
        </Switch>
    }

    return (
        <Router history={history}>
            {routes}
        </Router>
    )
}

export default AppRoutes;