import React, { useState, useEffect } from 'react';
import { Button, Collapse, List, Table, message } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Constants } from '../../config/constant';
import { useSelector } from 'react-redux';
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import './OrderByTrain.css';
import * as moment from 'moment';
import { NoOrders } from '../../assets/images/index';

const { Panel } = Collapse;

const OrderByTrain = ({ orders, onOrderClick, onConfirmClick }) => {
  const authToken = useSelector((state) => state.login.authToken);
  const [isTrainPassed, setIsTrainPassed] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const groupedItems = {};

  // Iterate through each order
  for (const order of orders) {
    const trainNo = order.train_no;

    // If the train number is not already a key in the groupedItems dictionary, create an empty array
    if (!groupedItems[trainNo]) {
      groupedItems[trainNo] = [];
    }

    // Add the order to the corresponding train number array
    groupedItems[trainNo].push(order);
  }

  const sortedItems = Object.entries(groupedItems).sort((a, b) => {
    const etaA = new Date(a[1][0].eta).getTime();
    const etaB = new Date(b[1][0].eta).getTime();
    return etaA - etaB;
  });

  const RenderOrdersList = ({ order }) => {
    const [status, setStatus] = useState(order.status);
    const [timer, setTimer] = useState(getTimerFromLocalStorage(order.order_id) || 5 * 60);

    const [preptimer, setprepTimer] = useState(getprepTimerFromLocalStorage(order.order_id) || 30 * 60);
    const [isOutForDelivery, setIsOutForDelivery] = useState(false);

    const handleAcceptOrder = (e, id) => {
      onConfirmClick(id);
      setStatus(Constants.ORDER_PREPARING);
    };

    const changeStatus = (order, note) => {
      let url = Apis.changeStatus(order.outlet_id, order.order_id, note);
      if (note) {
        url = url + `?note=${note}`;
      }

      HttpUtils.Get(url, { Authorization: 'Token ' + authToken })
        .then((res) => {
          const resData = JSON.parse(JSON.stringify(res))['data'];
          message.success(resData.message);
        })
        .catch((err) => {
          message.error(Constants.CANT_CONFIRM_ORDER);
          return err;
        });
    };

    const handleRejectOrder = (event, order) => {
      changeStatus(order, Constants.ORDER_CANCELLED);
      setStatus(Constants.ORDER_CANCELLED);
    };

    const handleOrderDelivered = (event, order) => {
      changeStatus(order, Constants.ORDER_DELIVERED);
      setStatus(Constants.ORDER_DELIVERED);
    };

    const handleOrderUndelivered = (event, order) => {
      changeStatus(order, Constants.ORDER_DELIVERED);
      setStatus(Constants.ORDER_DELIVERED);
    };

    useEffect(() => {
      let intervalId;
      let intervalsId;
      if (status === Constants.ORDER_PLACED) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer === 0) {
              onConfirmClick(order.data.orderId);
              // changeStatus(order, Constants.ORDER_C);
              setStatus(Constants.ORDER_PREPARING);
              return prevTimer;
            }
            const updatedTimer = prevTimer - 1;
            saveTimerToLocalStorage(order.order_id, updatedTimer);
            return updatedTimer;
          });
        }, 1000);
      } else if (status === Constants.ORDER_PREPARING) {
        intervalsId = setInterval(() => {
          setprepTimer((prevTimers) => {
            if (prevTimers === 0) {
              setIsOutForDelivery(true);
              // changeStatus(order, Constants.ORDER_DELIVERED);
              // setStatus(Constants.ORDER_CANCELLED);
              return prevTimers;
            }
            const updatedTimers = prevTimers - 1;
            saveprepTimerToLocalStorage(order.order_id, updatedTimers);
            return updatedTimers;
          });
        }, 1000);
      }

      return () => {
        if (status === Constants.ORDER_PLACED) {
          clearInterval(intervalId);
        } else if (status === Constants.ORDER_PREPARING) {
          clearInterval(intervalsId);
        }
      };
    }, [status, order.order_id]);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    let columns = [
      {
        title: 'Items',
        dataIndex: 'itemName',
        key: 'itemName',
        className: isTabletOrMobile ? 'custom-itemName' : 'custom-itemNameWeb',
        align: 'center',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        className: isTabletOrMobile ? 'custom-quantity-column' : 'custom-quantity-columnWeb',
        align: 'center',
      },
      {
        title: 'Price',
        dataIndex: 'sellingPrice',
        key: 'sellingPrice',
        className: isTabletOrMobile ? 'custom-price-column' : 'custom-price-columnWeb',
        align: 'center',
      },
    ];

    return (
      <List.Item key={order.id} className="list-item">
        <div className="order-container">
          <div
            onClick={(event) => {
              onOrderClick(event, order.data.orderId);
            }}
            className="order-orderbytrain-details"
          >
            <div className="ordercust-details">
              <div>
                <strong>
                  {order.data.deliveryDetails.coach}/{order.data.deliveryDetails.berth}
                </strong>
                <strong>~{order.data.customerDetails.customerName}</strong>
              </div>
              <strong>
                {Constants.ID}:{order.data.orderId}
              </strong>
            </div>
            <Table
              columns={columns}
              dataSource={order.data.orderItems.map((x, index) => {
                x.key = index + 1;
                return x;
              })}
              size="small"
              pagination={false}
              bordered
            />
            <div className="total-bill">
              <strong>
                {Constants.TOTAL_BILL} {getPaymentType(order)}{' '}
              </strong>
              <strong className="item-totalbill">Rs: {order.data.priceDetails.totalAmount}</strong>
            </div>
          </div>
          {isTrainPassed && status === Constants.ORDER_PLACED ? (
            <div className="buttonContainer">
              <Button
                type="primary"
                onClick={(event) => handleOrderDelivered(event, order)}
                className="order-delivered-btn"
              >
                <span id="accept">{Constants.DELIVERED} ? </span>
              </Button>
              <Button onClick={(event) => handleOrderUndelivered(event, order)} className="order-undelivered-btn">
                {Constants.UNDELIVERED}
              </Button>
            </div>
          ) : (
            status === Constants.ORDER_PLACED && (
              <div className="buttonContainer">
                <Button
                  type="primary"
                  onClick={(event) => handleAcceptOrder(event, order.data.orderId)}
                  className="accept-order"
                >
                  <span id="accept">{Constants.ACCEPT}</span> <span>({formatTime(timer)})</span>
                </Button>
                {/* TODO */}
                {/* <Button onClick={(event) => handleRejectOrder(event, order)} className="reject-order">
                  {Constants.REJECT}
                </Button> */}
              </div>
            )
          )}
          {isTrainPassed && status === Constants.ORDER_PREPARING ? (
            <div className="buttonContainer">
              <Button
                type="primary"
                onClick={(event) => handleOrderDelivered(event, order)}
                className="order-delivered-btn"
              >
                <span id="accept">{Constants.DELIVERED} ? </span>
              </Button>
              <Button onClick={(event) => handleOrderUndelivered(event, order)} className="order-undelivered-btn">
                {Constants.UNDELIVERED}
              </Button>
            </div>
          ) : isOutForDelivery && order.status === Constants.ORDER_PREPARING ? (
            <div className="buttonContainer">
              <Button
                type="primary"
                onClick={(event) => handleOrderDelivered(event, order)}
                className="order-delivered-btn"
              >
                <span id="accept">Out for delivery ? </span>
              </Button>
            </div>
          ) : (
            status === Constants.ORDER_PREPARING && (
              <div className="status-wrapper">
                {' '}
                <span className="status-preparing">
                  {Constants.PREPARING} {formatTime(preptimer)}
                </span>{' '}
              </div>
            )
          )}
          {(status === Constants.ORDER_CANCELLED || status === Constants.ORDER_UNDELIVERED) && (
            <div className="status-wrapper">
              <span className="status-cancelled">{status.replace('_', ' ')}</span>
            </div>
          )}
          {(status === Constants.ORDER_OUT_FOR_DELIVERY ||
            status === Constants.ORDER_PARTIALLY_DELIVERED ||
            status === Constants.ORDER_DELIVERED) && (
            <div className="status-wrapper">
              <span className="status-preparing">{status.replaceAll('_', ' ')}</span>
            </div>
          )}
        </div>
      </List.Item>
    );
  };

  return (
    <div>
      {orders?.length > 0 ? (
        <Collapse accordion>
          {sortedItems.map(([trainNo, orders], item) => (
            <Panel
              showArrow={false}
              className={`${getColorOfPanel(orders[0].eta)}`}
              header={
                <div className="panel-header">
                  <div className="textContainer">
                    <h3>
                      {trainNo}/ {orders[0].train_name}
                    </h3>
                    <h5>{moment(orders[0].eta).format('h:mm A, MMM D, YYYY')}</h5>
                  </div>
                  {/* TODO */}
                  {/* <div>
                    {orders[0].status === 'ORDER_PLACED' && (
                      <span style={{ color: 'green', marginRight: '10px' }}>{Constants.ORDER_PLACED}</span>
                    )}
                  </div> */}
                  <div className={orders.some((item) => item.status === 'ORDER_PLACED') ? 'order-length' : ''}>
                    <h2
                      className={
                        orders.some((item) => item.status === 'ORDER_PLACED') ? 'orders-placed-text' : 'order-text'
                      }
                    >
                      {orders.length}
                    </h2>
                  </div>
                </div>
              }
              key={trainNo}
            >
              <List
                dataSource={orders}
                renderItem={(order) => {
                  const givenDepartureTime = new Date(order.etd);
                  const currentDateTime = new Date();
                  if (givenDepartureTime < currentDateTime) {
                    setIsTrainPassed(true);
                  } else {
                    setIsTrainPassed(false);
                  }
                  return <RenderOrdersList order={order} isTrainPassed={isTrainPassed} />;
                }}
                pagination={false}
              />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <>
          <div className="no-order-container">
            <img className={isTabletOrMobile ? 'imgmobile-noorders' : ''} src={NoOrders} alt="No Orders" />
          </div>
          <h1 className={isTabletOrMobile ? 'noordersmobile-text' : 'noorder-webtext'}>{Constants.NO_ORDER_RECEIVE}</h1>
        </>
      )}
    </div>
  );
};

const getColorOfPanel = (eta) => {
  const etaTime = new Date(eta).getTime();
  const currentTime = new Date().getTime();
  const isPastETA = currentTime >= etaTime;

  return isPastETA ? 'past-eta-panel' : 'future-eta-panel';
};

const getPaymentType = (order) => {
  return order.data.paymentType === Constants.CASH_ON_DELIVERY ? Constants.COD : Constants.PREPAID;
};

const renderOrderItems = (order) => {
  return order.data.orderItems.map((item) => (
    <List.Item key={item.itemId}>
      {item.itemName} - Quantity: {item.quantity}
    </List.Item>
  ));
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const getTimerFromLocalStorage = (orderId) => {
  const timerValue = localStorage.getItem(`timer_${orderId}`);
  return timerValue ? parseInt(timerValue) : null;
};

const saveTimerToLocalStorage = (orderId, timerValue) => {
  localStorage.setItem(`timer_${orderId}`, timerValue.toString());
};

const getprepTimerFromLocalStorage = (orderId) => {
  const timerValue = localStorage.getItem(`preptimer_${orderId}`);
  return timerValue ? parseInt(timerValue) : null;
};

const saveprepTimerToLocalStorage = (orderId, timerValue) => {
  localStorage.setItem(`preptimer_${orderId}`, timerValue.toString());
};

export default OrderByTrain;
