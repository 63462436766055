import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    refreshTime: 120000,
    isActiveNotification:true
  },
  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    changeRefreshTime: (state, action) => {
      state.refreshTime = action.payload
    },
    changeNotificationState: (state, action) => {
      state.isActiveNotification = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeRefreshTime,changeNotificationState } = settingsSlice.actions

export default settingsSlice.reducer