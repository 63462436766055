import { Layout, Menu, Typography, Card, Switch } from 'antd';
import {
  LogoutOutlined,
  ShopOutlined,
  GlobalOutlined,
  CalendarOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import './sideBar.css'
import { logout as localStorageLogOut } from '../../utility/apiServices'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../login/loginSlice'
import { useHistory, useLocation } from "react-router-dom";
import * as moment from 'moment';
import { LogoSrc } from "../../assets/images/index";
import { useSpeechSynthesis } from 'react-speech-kit';
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga';
import { Constants } from '../../config/constant';
import { UtensilsSolid } from '../../assets/images/index';

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

const SideBar = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const location = useLocation();

  const pathname = location.pathname;

  let pageView;
  if (pathname === "*"){
    pageView = "/not-found"
  } else {
    pageView = pathname;
  }

  ReactGA.pageview(pageView)

  const { speak } = useSpeechSynthesis();

  const [collapsed, setCollapsed] = useState(isTabletOrMobile ? true : false)
  const selectedOutlet = useSelector((state) => state.login.selectedOutlet) 
  const onCollapse = collapsed => {
    setCollapsed(collapsed)
  };

  const dispatch = useDispatch()
  const history = useHistory();

  const logMeOut = () => {
    dispatch(logout())
    localStorageLogOut()
    return history.push({
      pathname: `/`,
    });
  }

  const onOrdersByDateClick = () => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    return history.push({
      pathname: `/orders/date/${today}`,
      state: { selectedTab: 'orders' }
    });
  }

  const onOutletsClick = () => {
    return history.push({
      pathname: `/`,
      state: { selectedTab: 'outlets' }
    });
  }
  const onOutletsDeatilsClick = () => {
    return history.push({
      pathname: `/outletDetails`,
      state: { selectedTab: 'outletDetails' }
  });
  }

  const onSettingsClick = () => {
    return history.push({
      pathname: `/settings`,
      state: { selectedTab: 'settings' }
    });
  }
  const onMenuItemsClick=()=>{
    return history.push({
      pathname: `/menuItems`,
      state: { selectedTab: 'menuItems' }
    });
  }
  const onFeedbackClick=()=>{
    return history.push({
      pathname: `/feedback`,
      state: { selectedTab: 'feedback' }
    });
  }


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} collapsedWidth={isTabletOrMobile ? 0 : 60 } onCollapse={onCollapse} breakpoint="md" className={ isTabletOrMobile === true ? 'mobileView' : ''} >
        <div className="logo2" />
        <Menu  theme="dark" defaultSelectedKeys={[location.state?.selectedTab]} mode="inline" className={isTabletOrMobile === true ?'menuMobileView': ''}>
          <Menu.Item key="orders" icon={<CalendarOutlined />} onClick={onOrdersByDateClick} >
            {Constants.ORDERS_BY_DATE}
          </Menu.Item>
          <Menu.Item key="outlets" icon={<ShopOutlined />} onClick={onOutletsClick} >
            {Constants.OUTLETS}
          </Menu.Item>
          <Menu.Item key="outletDetails" icon={<ShopOutlined />} onClick={onOutletsDeatilsClick} >
            {Constants.OUTLETS_DETAILS}
          </Menu.Item>
          <Menu.Item key="menuItems" icon={<ShopOutlined />} onClick={onMenuItemsClick} >
            {Constants.MENU_ITEMS}
          </Menu.Item>
          <Menu.Item key="feedback" icon={<GlobalOutlined />} onClick={onFeedbackClick} >
            {Constants.FEEDBACK}
          </Menu.Item>
          <Menu.Item key="settings" icon={<SettingOutlined />} onClick={onSettingsClick} >
           {Constants.SETTINGS}
          </Menu.Item>
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logMeOut}>
           {Constants.LOGOUT}
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={isTabletOrMobile ? {
            padding: 0,
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
          } : {
            padding: 0,
            height: 100,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }
          }
        >
          <img
            src={LogoSrc}
            alt="SpicyWagonLogo"
            className={isTabletOrMobile ? "logoMobileHeader" : "logoDesktopHeader"}
            onClick={() => speak({ text: 'Spicy Wagon' })} />
          <Card className={isTabletOrMobile ? 'ouletNameCard' : 'ouletNameCardDesktop'}>
            <img alt="text" src={UtensilsSolid} style={{height:"25px", width:"25px"}}/>
            <Title level={3}>{selectedOutlet.name}</Title>
          </Card>
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {
              props.componentToRender && props.componentToRender()
            }
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          {Constants.SPICY_WAGON}
        </Footer>
      </Layout>
    </Layout>
  );
}

export default SideBar