export const Constants = {
  //Drawer 
  ORDERS_BY_DATE:'Orders By Date',
  OUTLETS:"Outlets",
  OUTLETS_DETAILS:"Outlets Details",
  MENU_ITEMS:"Menu Items",
  FEEDBACK:'Feedback',
  SETTINGS: "Settings",
  LOGOUT:'Logout',


  // String Constants
  ENTERMOBILEMO: "Please Enter Valid Mobile Number!",
  MOBILE_NO: "Enter Mobile No",
  OTP: "Please Enter OTP!",
  PASSWORD: "Please Enter Password!",
  REFRESHOFFER: "Refresh to get new Orders",
  NEWORDER: "New Orders",
  NEW_ORDER_DESCRIPTION: `New Orders recieved`,
  SELECT_OUTLET: "Select Outlet",
  SETTING_MESSAGE: "Usage:",
  SETTING_DESCRIPTION:
    "The exact time after which a request to check whether we have a new order is being sent! This happens in exact intervals of above mentioned",
  UNAUTHVENDOR: "unAuthVendor",
  UNAUTHPASSENGER: "unAuthPassenger",
  ORDER_PLACED: "ORDER_PLACED",
  ORDER_PREPARING: "ORDER_PREPARING",
  ORDER_PREPARED: "ORDER_PREPARED",
  ORDER_OUT_FOR_DELIVERY: "ORDER_OUT_FOR_DELIVERY",
  ORDER_DELIVERED: "ORDER_DELIVERED",
  ORDER_PARTIALLY_DELIVERED: "ORDER_PARTIALLY_DELIVERED",
  ORDER_UNDELIVERED: "ORDER_UNDELIVERED",
  ORDER_CANCELLED: "ORDER_CANCELLED",
  OPERATOR: "Operator",
  PASSENGER: "Passenger",
  CHITTI: "Chitti",
  VENDOR: "Vendor",
  CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
  PASSENGER_DETAILS: "Passenger Details",
  THANKS_FOR_CONFIRMING: "Thanks for Confirming",
  PLEASE_CONFIRM_YOURORDER: "Please Confirm Your Order",
  NETWORKERROR: "Network Error",
  SELECT_STATUS: "Select the Status",
  ORDER_ID:'Order ID',
  AMOUNT:'Amount',
  STATUS:'Status',
  ORDER_DETAILS:'Order Details',
  CHANGE_STATUS:'CHANGE STATUS',
  MARK_CONFIRMED:"MARK CONFIRMED",
  ACCEPT: 'Accept',
  REJECT:'Reject',
  PREPARING:'Preparing',
  CANCELLED:'Cancelled',
  NO_ORDER_RECEIVE:'No Orders Receive Yet',
  ORDER_BY_DATE:'Orders By Date',
  IRCTC_PARTNER:'Authorised IRCTC Partner',
  SUBMIT:'Submit',
  ID:'ID',
  TOTAL_BILL:'Total Bill',
  ADD_EVENT:'Add Event',
  IRCTC_AUTHORISED:'IRCTC Authorised',
  ORDERS_DETAILS:'Orders Details',
  COD:'COD',
  PREPAID:'Prepaid',
  CONFIRMED:'Confirmed',
  NOT_CONFIRMED:'Not Confirmed',
  GO:'Go',
  PLEASE_SELECT_THE_TIME_FOR_REFRESH:'Please select the time for refresh',
  SPICY_WAGON:'Spicy Wagon',
  SELECT_THE_CURRENT_STATE:'Select the current state of the order',
  PREPARED:'Prepared',
  OUT_FOR_DELIVERY:'Out for Delivery',
  DELIVERED:'Delivered',
  PARTIALLY_DELIVERED:'Partially Delivered',
  UNDELIVERED:'Undelivered',
  CANT_CONFIRM_ORDER:`Can't Confirm Order.`,
  ORDER_CONFIRM_BY_OPERATOR:'When clicked the OK button, this order will be confirmed by the operator',
  SUCCESSFULLY_CAHNGE_STATUS:'Successfully Changed Active Status for id:',
  IN_STOCK:'In Stock',
  PLEASE_SELECT_OUTLET:'Please Select the Outlets',
  SPICY_VAGON_RESTAURANT:'Spicy Wagon Restaurant Partner Dashboard',
  LOADING: 'loading...',
  VENDORS_ASSOCIATED:'Vendors Associated ',
  FEEDBACK_SELECTED_DATES:'Feedbacks With In Selected Dates',
  ITEM_NAME:'Item Name',
  LOGIN:"Login",
  SELECT_DATE:'Select Date',
  NOTIFICATION:'Notification',
  GMAIL:'https://www.gmail.com',
  WHATSAPP:'https://web.whatsapp.com/',
  PASSWORD_LOGIN:'Password Login',
  OTP_LOGIN:'OTP Login',
  REFRESH_TIMING:'Refresh timing',
  HELP:'Help',
  REFRESH_TIME_CHANGED:`Refresh time changed to mins`,
  EMAIL: 'Email',
  CALL : 'Call',
  HELP_WHATSAPP:'Whatsapp',
  RECVIED_ORDER:'Spicy Wagon Order Recieved',
  NOTIFICATION_ACTION:"We'd like to show you notifications for the latest news and updates about the following categories.",
  ACCEPT_BUTTON_TEXT: "OMG YEEEEESS!",
  CANCEL_BUTTON_TEXT: "NAHHH",
  THANKS_FOR_SUBSCRIBING: "Thanks for subscribing!",
  ONE_SIGNAL:"One Signal",
  RESTAURANT: 'Restaurant Details',
  NAME:'Name',
  OUTLET_NAME:'Outlet Name',
  OUTLET_ID: 'Outlet Id',
  MOBILE:'Mobile',
  ALTERNATE_MOBILE: 'Alternate Mobile',
  PNR:'PNR',
  BERTH: 'Berth',
  STATION: 'Station',
  TRAIN : 'Train',
  ETA: 'ETA',
  ETD: 'ETD',
  ITEMS: 'Items',
  DESCRIPTION: 'Description',
  QUANTITY: 'Quantity',
  BASE_PRICE: 'Base Price',
  TOTAL_TAX: 'Total Tax',
  SELLING_PRICE: 'Selling Price',
  TOTAL_PRICE: 'Total Price',
  TYPE: 'Type',
  PRICE: 'Price',
  PRICE_BREAK_UP: 'Price Break Up',
  PAYMENT_TYPE: 'Payment Type:',
  DELIVERY_BOY_DETAILS: 'Delivery Boy Details',
  EVENTS: 'Events',
  ADD_EVENT_BTN: 'Add Event',
  NO_DELIVERY_EXECUTIVE_ASSIGNED: 'No Delivery Executive Assigned',
  TEXT_ORDER_PLACED: 'Order Placed',
  TEXT_ORDER_PREPARING: 'Order Preparing',
  TEXT_ORDER_PREPARED:'Order Prepared',
  TEXT_OUT_FOR_DELIVERY:'Out for Delivery',
  TEXT_DELIVERED: 'Delivered',
  TEXT_PARTIALLY_DELIVERED: 'Partially Delivered',
  TEXT_UNDELIVERED: 'Undelivered',
  TEXT_ORDER_CANCELLED: 'Order Cancelled'
};

export const adminContactDetails ={
  EMAIL_US:'Email us at admin@spicywagon.in',
  CALL_AT:'Call at 7428448998',
  CONNECT_ON_WHATSAPP:'Connect with on Whatsapp',
}

export const validationRules = {
  MOBILEVALIDATION: {
    required: true,
    maxLength: 10,
    pattern: /^[1-9a-zA-Z]\d{9}$/,
    message: Constants.ENTERMOBILEMO,
  },
  ENTEROTP: {
    required: true,
    message: Constants.OTP,
  },
  PASSWARDVALIDATION: {
    required: true,
    message: Constants.PASSWORD,
  },
  ORDERNOTERULES: {
    required: true,
    message: "Please Enter Note!",
  },
};


export const columnsForPriceBreakUp = [
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
    }
]