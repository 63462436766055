const pageRoutes = {
    login: '/login',
    home: '/home',
    ordersByDate: '/orders/date/:date',
    outletSelection: '/',
    // ordersForStationByDate: '/#orders/station/date/:date',
    // ordersForTrainByDate: '/#orders/train/date/:date',
    // ordersForOutletByDate: '/#orders/outlet/date/:date',
    orderDetails: '/order/id/:id/date/:date',
    example: '/example',
    settings: '/settings',
    outletDetails: '/outletDetails',
    menuItems:'/menuItems',
    feedback:'/feedback',

    // UnAuthenticated End Points
    orderDetailsVendorUnAuth: `/vendor-open/:pnr/:order_id`,
    orderDetailsPassengerUnAuth: `/passenger-open/:pnr/:order_id`,
}

export default pageRoutes;
