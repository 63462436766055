import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import HttpUtils from "../utility/httpUtils";
import Apis from "../config/apiEndpoints";
import LocalStorageVariables from "../config/localStorage";
import OutletDetailsScreen from "../views/outletDetails/OutletDetailsScreen";

const OutletDetailsViewModel = (props) => {
  const authToken = useSelector((state) => state.login.authToken);
  const [outletData, setOutletData] = useState({});

  const getOutletDetails = (stationCode) => {
    const outletId = localStorage.getItem(
      LocalStorageVariables.vendor_outlet_id
    );
    let url = Apis.getOutletDetails(outletId);
    HttpUtils.Get(url, { Authorization: "Token " + authToken })
      .then((res) => {
        const resData = JSON.parse(JSON.stringify(res))["data"];
        setOutletData(resData);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    getOutletDetails();
    //eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Is Active",
      dataIndex: "isActiveDisplay",
      key: "isActiveDisplay",
    },
  ];

  return <OutletDetailsScreen {...{ outletData, columns }} />;
};

export default OutletDetailsViewModel;
