import axios from 'axios';
import {store} from '../redux/store';
import {BaseURL} from '../config/apiEndpoints';

const token = () => {
  const state = store?.getState().login;
  let authToken = state?.authToken;
  return `token ${authToken}`;
};

const instance = axios.create({
  baseURL: BaseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export function sendGetRequest(url) {
  return instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response === undefined) {
        throw new Error('something went wrong');
      }
      return err.response.data;
    });
}
export function sendPostRequest(url, body) {
  return instance
    .post(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response === undefined) {
        throw new Error('something went wrong');
      }
      return err.response.data;
    });
}

export function sendGetRequestWithToken(url) {
  instance.defaults.headers.common.Authorization = token();
  return instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response === undefined) {
        throw new Error('something went wrong');
      }
      return err.response.data;
    });
}

export function sendPostRequestWithToken(url, body) {
  instance.defaults.headers.common.Authorization = token();
  return instance
    .post(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response === undefined) {
        throw new Error('something went wrong');
      }
      return err.response.data;
    })
}
export function sendPatchRequestWithToken(url, body) {
  instance.defaults.headers.common.Authorization = token();
  return instance
    .patch(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response === undefined) {
        throw new Error('something went wrong');
      }
      return err.response.data;
    })
}

