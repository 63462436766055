import React, { useEffect, useState } from "react";
import {
  message,
  Image,
  Switch,
} from "antd";
import Apis from "../config/apiEndpoints";
import LocalStorageVariables from "../config/localStorage";
import { DefaultImage } from "../assets/images";
import {
  sendGetRequestWithToken,
  sendPatchRequestWithToken,
} from "../network/Network";
import MenuItemScreen from "../views/menuItems/MenuItems";
import { Constants } from "../config/constant";

const MenuItemsViewModel = (props) => {
  const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);

  // This is for menu items display in MenuItems tab
  const [menuItemsDisplay, setMenuItemsDisplay] = useState([]);
  const [render,setRender]=useState(false)

  useEffect(() => {
    getMenuItems();
    //eslint-disable-next-line
  }, [render]);

  const groupedData = {};

  menuItemsDisplay.forEach((item) => {
    const foodType = item.foodType;

    if (!groupedData[foodType]) {
      groupedData[foodType] = [];
    }

    groupedData[foodType].push(item);
  });

  const getMenuItems = async () => {
    let url = Apis.getMenuItems(outletId);
    await sendGetRequestWithToken(url)
      .then((res) => {
        setMenuItemsDisplay(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const changeIsActiveItem = async (record, value) => {
    let body = {
      is_active: value,
    };
    let url = Apis.menuItemOperations(record.itemId);
    await sendPatchRequestWithToken(url, body).then((res) => {
      message.success(
        `${Constants.SUCCESSFULLY_CAHNGE_STATUS} ${record.itemId}`
      );
      setRender(!render)
    });
  };

  const columnsForMenuItems = [
    {
      title: "ID",
      dataIndex: "itemId",
      key: "itemId",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image, record) => (
        <Image width={50} src={image ? image : DefaultImage} />
      ),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Opening Time",
      dataIndex: "openingTime",
      key: "openingTime",
    },
    {
      title: "Closing Time",
      dataIndex: "closingTime",
      key: "closingTime",
    },
    {
      title: "In Stock",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active, record) => {
        return (
          <Switch
            checked={is_active}
            onClick={() => changeIsActiveItem(record, !is_active)}
          />
        );
      },
    },
    //TODO
    // {
    //   title: "Edit Menu",
    //   key: "action",
    //   render: (action, record) => (
    //     <div>
    //       <Button type="link" onClick={() => onMenuItemEdit(record)}>
    //         Edit
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  return <MenuItemScreen {...{ columnsForMenuItems, groupedData }} />;
};

export default MenuItemsViewModel;
