import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, message, Spin } from 'antd';
import './login.css'
import { getLoginOtp, verifyLoginOtp, autoRegister, getRoles } from '../utility/apiServices'
import { useDispatch, useSelector } from 'react-redux'
import { loginSuccessful, rolesSuccessful,startOtpLoader, stopOtpLoader } from './loginSlice'
import { IrctcLogo } from "../assets/images";
import { useMediaQuery } from 'react-responsive';
import { Constants, validationRules } from '../config/constant';

const { Search } = Input;

const LoginOTP = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const loading = useSelector((state) => state.login.otpLoading)
    const dispatch = useDispatch()
    const { MOBILENO } = Constants
    const { MOBILEVALIDATION, ENTEROTP } = validationRules

    const [data, setData] = useState({
        sessionId: null,
        error: null,
        msg: null
    })

    const [timer, setTimer] = useState(0);

    // Function to start the timer
    const startTimer = () => {
        setTimer(60); // Reset remaining time to 60 seconds
    };

    useEffect(() => {
        if (timer > 0) {
          setTimeout(() => setTimer(timer - 1), 1000);
        }
      });   

    const onFinish = async (values) => {
        dispatch(startOtpLoader())
        let auth_token = await verifyLoginOtp(data.sessionId, values["mobileNumber"], values["otp"])
        autoRegister(auth_token, values["mobileNumber"])
        dispatch(loginSuccessful({ authToken: auth_token, mobileNumber: values["mobileNumber"] }))
        dispatch(stopOtpLoader())
        message.success('successfully Logged In');
        getRoles(auth_token).then(res => {
            dispatch(rolesSuccessful({ roles: res }))
        })
    };

    const onFinishFailed = (errorInfo) => {
        message.error(`Can't Log In`);
    };

    const onSearch = async (value) => {

            dispatch(startOtpLoader())
            let res = await getLoginOtp(value)
            dispatch(stopOtpLoader())
            setData({
                ...data,
                sessionId: res.sms_session_id,
                msg: res.msg,
                error: res.error
            })

            if(res.sms_session_id)
            {
                startTimer();
            }                      
    }

    return (
        <>
            <div>{ loading && <Spin className="spinner" /> } </div>
            <div className={isTabletOrMobile ? 'containerDivMobile' : 'containerDiv'}>
                <div>
                    <Card 
                    className='loginpass-card'
                    >
                        <img src={IrctcLogo} className='loginpass-irctclogo' alt={Constants.LOADING} />
                        <span className='irctc-text'>{Constants.IRCTC_PARTNER}</span>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            className={isTabletOrMobile ? "loginMobile" : "login"}
                        >
                            <Form.Item
                                label="Mobile No."
                                name="mobileNumber"
                                rules={[
                                    MOBILEVALIDATION
                                ]}
                            >
                                <Search
                                    placeholder={MOBILENO}
                                    allowClear
                                    enterButton="Generate OTP"
                                    size="large"
                                    onSearch={onSearch}
                                    disabled={timer > 0}
                                />
                            </Form.Item>

                            {
                            data.sessionId && 
                                <Form.Item
                                    label="OTP"
                                    name="otp"
                                    rules={[
                                        ENTEROTP
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            }

                            {data.sessionId && (timer > 0) && (
                                <Form.Item className="timer-text">
                                    Resend OTP in 
                                    <span className='timer-color'> {timer} </span> seconds
                                </Form.Item>
                            )}

                            <Form.Item 
                            className={isTabletOrMobile ? "loginotp-submitbuttonmobile":"loginotp-submitbuttonweb"}
                            >
                                <Button type="primary" htmlType="submit" id='submit' block>
                                    {Constants.SUBMIT}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default LoginOTP;