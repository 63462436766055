import "./App.css";
import React, { useEffect } from "react";
import AppRoutes from "./routing/router";
import { checkAuthInfo, getRoles } from "./utility/apiServices";
import LocalStorageVariables from "./config/localStorage";
import { loginSuccessful, rolesSuccessful } from "./login/loginSlice";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { Constants } from "./config/constant";

const TRACKING_ID = "UA-216131011-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  TagManager.initialize({
    gtmId: "G-5TGB0F64YX", // Replace GTM-XXXXXX with your Google Tag Manager ID
  });
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  // This code will be use in future
  //   console.log("here")
  //   OneSignal.init({
  //     appId: "c8a43052-5bd8-4372-a677-a6b9b45db862"
  //   });
  // }, []);

  // OneSignal.push(function() {
  //   /* These examples are all valid */
  //   OneSignal.getUserId(function(userId) {
  //     console.log("OneSignal User ID:", userId);
  //     // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
  //   });
  // });

  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init(
        {
          appId: "c8a43052-5bd8-4372-a677-a6b9b45db862",
          promptOptions: {
            slidedown: {
              enabled: true,
              actionMessage:Constants.NOTIFICATION_ACTION
                ,
              acceptButtonText: Constants.ACCEPT_BUTTON_TEXT,
              cancelButtonText: Constants.CANCEL_BUTTON_TEXT,
            },
          },
          welcomeNotification: {
            title: Constants.ONE_SIGNAL,
            message: Constants.THANKS_FOR_SUBSCRIBING,
          },
        },
        //Automatically subscribe to the new_app_version tag
        OneSignal.sendTag("new_app_version", "new_app_version", (tagsSent) => {
          // Callback called when tag has finished sending
        })
      );
    });
    //eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    checkAuthInfo().then((res) => {
      if (res.isLoggedIn) {
        getRoles(res.token).then((res) => {
          dispatch(rolesSuccessful({ roles: res }));
          let externalUserId = res[0].id; // You will supply the external user id to the OneSignal SDK
          OneSignal.push(function () {
            OneSignal.setExternalUserId(externalUserId.toString());
          });
        });
        dispatch(
          loginSuccessful({
            authToken: res.token,
            mobileNumber: res.mobileNumber,
            vendorOutletId: res.vendorOutletId,
          })
        );
      }
    });
    //eslint-disable-next-line
  }, [dispatch]);

  const isAuthenticated = localStorage.getItem(
    LocalStorageVariables.auth_token
  );
  return (
    <div>
      
      <AppRoutes isAuthenticated={isAuthenticated} />
    </div>
  );
};

export default App;

// External User - tags : chat rooms - subscription ---
// Sending a set of people common subscription message
