import React, { useEffect, useState, Fragment } from 'react';
import { Table, Card, Typography, Button, Modal, Spin, message, Form, Input, Collapse, Col, Row, Layout } from 'antd';
import * as moment from 'moment';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import './orderDetails.css'
import { useParams } from "react-router-dom";
import { ShoppingTwoTone, CheckCircleTwoTone, ArrowRightOutlined } from '@ant-design/icons';
import LocalStorageVariables from '../../config/localStorage';
import { useMediaQuery } from 'react-responsive';
import { LogoSrc,CashOnD, PAID, OrderPlaced, PreparedFinal, OrderCancel, UNDELIVERED, PartiallyDelivered, Cooking, FoodDelivery, Delivery, SUPPORT, ROBOT, ColorRestaurant, MAN }from "../../assets/images/index";
import TimeLine from '../timeLine/timeLine';
import { Constants, validationRules, columnsForPriceBreakUp } from '../../config/constant';

const { confirm } = Modal;
const { Title, Text } = Typography;
const { TextArea } = Input;

const OrderDetails = (props) => {
  const { Content } = Layout;


    const { origin } = props;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { UNAUTHVENDOR, UNAUTHPASSENGER, ORDER_PLACED, ORDER_PREPARING, ORDER_PREPARED, ORDER_OUT_FOR_DELIVERY, 
    ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED, ORDER_CANCELLED,OPERATOR,
    PASSENGER,CHITTI, VENDOR,NETWORKERROR} = Constants
    const { ORDERNOTERULES } = validationRules

    const params = useParams()

    const authToken = useSelector((state) => state.login.authToken)

    const [data, setData] = useState({
        details: {},
        availableExecutives: [],
        selectedExecutive: {}
    })
    console.log("🚀 ~ file: orderDetails.js:40 ~ OrderDetails ~ data:", data)
    const [isLoading, setIsLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0)

    const fetchOrderDetails = () => {
        (origin === UNAUTHVENDOR || origin === UNAUTHPASSENGER) ?
            fetchOrderDetailsUnAuth() : fetchOrderDetailsAuth()
    }

    const { Panel } = Collapse;

    function callback(key) {
        console.log(key);
    }

    const currentStepIdentifier = (a) => {
        switch (a) {
            case ORDER_PLACED:
                return 0
            case ORDER_PREPARING :
                return 1
            case ORDER_PREPARED:
                return 2
            case ORDER_OUT_FOR_DELIVERY:
                return 3
            case ORDER_DELIVERED:
                return 4
            case ORDER_PARTIALLY_DELIVERED:
                return 4
            case ORDER_UNDELIVERED:
                return 4
            case ORDER_CANCELLED:
                return 4
            default:
                return 0
        }
    }

    let roleImageView = (role, createdBy) => {
        switch (role) {
            case OPERATOR:
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={SUPPORT} alt={OPERATOR} className="tablelogo" />
                </div>
            case PASSENGER:
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={MAN} alt={PASSENGER} className="tablelogo" />
                </div>
            case CHITTI:
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={ROBOT} alt={CHITTI} className="tablelogo" />
                </div>
            case VENDOR:
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={ColorRestaurant} alt={VENDOR} className="tablelogo" />
                </div>
            default:
                return <div>?  </div>
        }
    }

    // Keep by beside image

    let readableStatusView = (status) => {
        switch (status) {
            case ORDER_PLACED:
                return <div className="readStatusInside">
                    <img src={OrderPlaced} alt="Order Placed" className="tablelogo" />
                    <p>{Constants.TEXT_ORDER_PLACED}</p>
                </div>
            case ORDER_PREPARING:
                return <div className="readStatusInside">
                    <img src={Cooking} alt="Order Preparing" className="tablelogo" />
                    <p>{Constants.TEXT_ORDER_PREPARING}</p>
                </div>
            case ORDER_PREPARED:
                return <div className="readStatusInside">
                    <img src={PreparedFinal} alt="Order Prepared" className="tablelogo" />
                    <p>{Constants.TEXT_ORDER_PREPARED}</p>
                </div>
            case ORDER_OUT_FOR_DELIVERY:
                return <div className="readStatusInside">
                    <img src={Delivery} alt="Out for Delivery" className="tablelogo" />
                    <p>{Constants.TEXT_OUT_FOR_DELIVERY}</p>
                </div>
            case ORDER_DELIVERED:
                return <div className="readStatusInside">
                    <img src={FoodDelivery} alt="Delivered" className="tablelogo" />
                    <p>{Constants.TEXT_DELIVERED}</p>
                </div>
            case ORDER_PARTIALLY_DELIVERED:
                return <div className="readStatusInside">
                    <img src={PartiallyDelivered} alt="Order Cancelled" className="tablelogo" />
                    <p>{Constants.TEXT_ORDER_CANCELLED}</p>
                </div>
            case ORDER_UNDELIVERED:
                return <div className="readStatusInside">
                    <img src={UNDELIVERED} alt="Order Cancelled" className="tablelogo" />
                    <p>{Constants.TEXT_UNDELIVERED}</p>
                </div>
            case ORDER_CANCELLED:
                return <div className="readStatusInside">
                    <img src={OrderCancel} alt="Order Cancelled" className="tablelogo" />
                    <p>{Constants.TEXT_ORDER_CANCELLED}</p>
                </div>
            default:
                return <div>?  </div>
        }
    }

    const fetchOrderDetailsAuth = () => {
        const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);
        let url = Apis.getOrdersDetails(outletId, params.id);
        setIsLoading(true)
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setData({
                    ...data,
                    details: resData
                })
                setCurrentStep(currentStepIdentifier(resData.status))
                setIsLoading(false)
            })
            .catch(err => {
                message.error(`Error`, err);
            });
    }

    const fetchOrderDetailsUnAuth = () => {
        HttpUtils.Get(Apis.getOrderDetailsUnAuth(params.pnr, params.order_id))
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setData({
                    ...data,
                    details: resData
                })
                setCurrentStep(currentStepIdentifier(resData.status))
                setIsLoading(false)
            })
            .catch(err => {
                message.error(`Error`, err);
            });
    }

    const confirmOrder = () => {
        const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);
        let url = Apis.confirmOrder(outletId, params.id);
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                fetchOrderDetails()
                message.success('Order Confirmed');
                return resData
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    const confirmVendorUnAuth = () => {
        let url = Apis.vendorConfirmUnAuth(params.pnr, params.order_id);
        HttpUtils.Get(url)
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                fetchOrderDetails()
                message.success('Order Confirmed By Vendor');
                return resData
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    const confirmPassengerUnAuth = () => {
        let url = Apis.passengerConfirmUnAuth(params.pnr, params.order_id);
        HttpUtils.Get(url)
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                fetchOrderDetails()
                message.success('Order Confirmed By Passenger');
                return resData
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    useEffect(() => {
        fetchOrderDetails()
        //eslint-disable-next-line
    }, []);

    let priceDetails = data && data.details.data?.priceDetails

    // TODO need to work on discount

    let dataForPriceTable = priceDetails && [
        {
            type: 'Sub Total (Before GST)',
            price: priceDetails["totalAmount"] - priceDetails["gst"],
        },
        {
            type: 'GST',
            price: priceDetails["gst"],
        },
        {
            type: 'Total Amount',
            price: priceDetails["totalAmount"],
        },
        {
            type: 'Delivery Charge',
            price: priceDetails["deliveryCharge"],
        },
        {
            type: 'Amount Payable',
            price: priceDetails["amountPayable"],
        },
    ]

    let columns = [
        {
          title: '',
          dataIndex: 'icon',
          key: 'icon',
          render: (text, record) => (
            <ShoppingTwoTone
              twoToneColor={record.isVegetarian ? '#52c41a' : '#eb2f96'}
            />
          ),
        },
        {
          title: () => <span className="headerStyle">{Constants.ITEMS}</span>,
          dataIndex: 'itemName',
          key: 'itemName',
        },
        {
          title: () => <span className="headerStyle">{Constants.DESCRIPTION}</span>,
          dataIndex: 'description',
          key: 'description',
          render: (text) => (
            <div className='item-des'>{text}</div>
          ),
        },
        {
          title: () => <span className="headerStyle">{Constants.QUANTITY}</span>,
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
          title: () => <span className="headerStyle">{Constants.BASE_PRICE}</span>,
          dataIndex: 'basePrice',
          key: 'basePrice',
        },
        {
          title: () => <span className="headerStyle">{Constants.TOTAL_TAX}</span>,
          dataIndex: 'tax',
          key: 'tax',
        },
        {
          title: () => (
            <span className="headerStyle">{Constants.SELLING_PRICE}</span>
          ),
          dataIndex: 'sellingPrice',
          key: 'sellingPrice',
        },
        {
          title: () => <span className="headerStyle">{Constants.TOTAL_PRICE}</span>,
          dataIndex: 'sellingPrice',
          key: 'sellingPrice',
          render: (text, record) => (
            <span>{(record.quantity * record.sellingPrice).toFixed(2)}</span>
          ),
        },
      ];
 
      const mobileColumns = [
        {
            title: () => <span className="headerStyle">{Constants.ITEMS}</span>,
            dataIndex: 'itemName',
            key: 'itemName',
          },
          {
            title: () => <span className="headerStyle">{Constants.QUANTITY}</span>,
            dataIndex: 'quantity',
            key: 'quantity',
          },
          {
            title: () => <span className="headerStyle">{Constants.TOTAL_PRICE}</span>,
            dataIndex: 'sellingPrice',
            key: 'sellingPrice',
            render: (text, record) => (
              <span>{(record.quantity * record.sellingPrice).toFixed(2)}</span>
            ),
          },

      ]
    const showPromiseConfirm = () => {
        confirm({
            title: 'Do you want to confirm the order?',
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            content: Constants.ORDER_CONFIRM_BY_OPERATOR,
            onOk() {
                if (origin === UNAUTHVENDOR) {
                    confirmVendorUnAuth();
                } else if (origin === UNAUTHPASSENGER) {
                    confirmPassengerUnAuth();
                } else {
                    confirmOrder();
                }
            },
            onCancel() { },
        });
    }

    const showEventModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);
        const formData = {
            description: 'Vendor Event',
            data: {
                note: values['note']
            }
        }
        let url = Apis.eventsCall(outletId, params.id);
        return HttpUtils.Post(url,
            formData,
            { Authorization: 'Token ' + authToken },
            { 'content-type': 'multipart/form-data' })
            .then(res => {
                fetchOrderDetails()
                setIsModalVisible(false);
                message.success('Event Added');
            })
            .catch(err => {
                setIsModalVisible(false);
                return Promise.reject(err && err.response &&
                    err.response.data && err.response.data.message ? err.response.data.message : NETWORKERROR);

            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const addEventModal = <Modal
        title="Add an Event"
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
            <Button form="eventForm" key="submit" htmlType="submit">
                {Constants.SUBMIT}
            </Button>
        ]}
        onCancel={handleCancel}
    >
        <Form
            id="eventForm"
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Description"
                name="description"
            >
                <Input defaultValue="Vendor Event" disabled />
            </Form.Item>
            <Form.Item
                label="Note"
                name="note"
                rules={[
                    ORDERNOTERULES
                ]}
            >
                <TextArea rows={4} />
            </Form.Item>
        </Form>
    </Modal>

    return (
        <>
        {isTabletOrMobile ? (
          <div className="main-container">
            <div>
              <Title className="order-detail-headline-mobile" level={2}>
                <span className={isTabletOrMobile ? 'order-headline-mobile' :'order-headline'}>{Constants.ORDER_DETAILS}</span>
              </Title>
              <h2 className="order-id-mobile">
                {Constants.ORDER_ID} {data.details.order_id}
              </h2>
            </div>
            { Object.keys(data.details).length === 0 ? <Spin />:<TimeLine data={data} currentStep={currentStep} origin={origin ? origin : null} fetchOrderDetails={fetchOrderDetails} showPromiseConfirm={showPromiseConfirm}/>}
            {addEventModal}
            <div>
              {isLoading === true && <Spin size="large" className="spinner" />}
            </div>
            {data.details.data && (
              <>
                <div className="mobile-container">
                  <Content className="order-summary-wrapper-mobile">
                    <div
                      className={
                        isTabletOrMobile
                          ? 'order-details-mobile'
                          : 'order-details'
                      }
                    >
                      <div className="restaurant-details-wrapper">
                        <span className="order-detail-sub-heading">
                          {Constants.PNR}
                        </span>
                        <span className="orderDetailsText">
                          <b>{data.details.data.deliveryDetails.maskedPnr}</b>
                        </span>
                        <span className="order-detail-sub-heading">
                          {Constants.PASSENGER_DETAILS}
                        </span>
                        <span className="orderDetailsText">
                          {data.details.data.customerDetails.customerName}
                        </span>
                        <span className="orderDetailsText">
                          <b>{data.details.data.customerDetails.mobile}</b>
                        </span>
                        <span className="orderDetailsText">
                          {data.details.data.customerDetails.alternateMobile}
                        </span>
                      </div>
                      <div className="customer-detail-wrapper-mobile">
                        <span className="order-detail-sub-heading">
                          {Constants.DELIVERY_INFORMATION}
                        </span>
                        <span className="orderDetailsText">
                          <b>
                            {data.details.data.deliveryDetails.trainNo} -{' '}
                            {data.details.data.deliveryDetails.trainName}
                          </b>
                        </span>
                        <span className="orderDetailsText">
                          <b>
                            {data.details.data.deliveryDetails.station} (
                            {data.details.data.deliveryDetails.stationCode})
                          </b>
                        </span>
                        <span className="orderDetailsText">
                          <b>
                            {data.details.data.deliveryDetails.coach} -{' '}
                            {data.details.data.deliveryDetails.berth}
                          </b>
                        </span>
                        <span className="orderDetailsText">
                          {Constants.ETA} {moment(data.details.eta).format('LLL')}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.ETD} {moment(data.details.etd).format('LLL')}
                        </span>
                      </div>
                    </div>
                    <div className="order-status-wrapper-mobile">
                      <h1 className="order-status">
                        {data.details.status.replace(/_/g, '  ')}
                      </h1>
                      <h1 className="payment-type-mobile">
                        {data.details.data.paymentType.replace(/_/g, '  ')}
                      </h1>
                    </div>
                    <div>
                      <Table
                        columns={mobileColumns}
                        dataSource={data?.details?.data?.orderItems}
                        size="small"
                        pagination={false}
                        bordered
                        className="table"
                      />
                      <Collapse defaultActiveKey={['1']}>
                        <Panel
                          header={
                            <span className="headerStyle">
                              {Constants.PRICE_BREAK_UP}
                            </span>
                          }
                          key="1"
                        >
                          <Table
                            columns={columnsForPriceBreakUp}
                            dataSource={dataForPriceTable}
                            size="small"
                            pagination={false}
                            bordered
                            className="table"
                          />
                        </Panel>
                      </Collapse>
                      &nbsp;
                    </div>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                          title={<b>{Constants.DELIVERY_BOY_DETAILS}</b>}
                          className="card-details"
                          extra={
                            <Button
                              type="primary"
                              ghost
                              disabled={data.details.delivery_jobs.length > 0}
                            >
                              {Constants.ASSIGN_BTN}
                            </Button>
                          }
                        >
                          {data.details.delivery_jobs.length > 0 ? (
                            data.details.delivery_jobs.map((x) => {
                              return (
                                <Fragment>
                                  <p className={isTabletOrMobile && 'para'}>
                                    {' '}
                                    {Constants.ID} {x.id}, {Constants.NAME}:
                                    {x.delivery_executive.name}
                                  </p>
                                  <p className={isTabletOrMobile && 'para'}>
                                    {Constants.MOBILE} :{' '}
                                    {x.delivery_executive.phone_number}
                                  </p>
                                </Fragment>
                              );
                            })
                          ) : (
                            <p className={isTabletOrMobile && 'para'}>
                              {Constants.NO_DELIVERY_EXECUTIVE_ASSIGNED}
                            </p>
                          )}
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                          title={<b>{Constants.EVENTS}</b>}
                          // extra={
                          //   <Button type="primary" ghost onClick={showEventModal}>
                          //     {Constants.ADD_EVENT_BTN}
                          //   </Button>
                          // }
                          className="card-details"
                        >
                          {data.details.events.map((x) => {
                            let stateChangeArr =
                              x.data &&
                              x.data.state_change &&
                              x.data.state_change.match(/ORDER_\w*/g);
                            return (
                              <Card
                                size="small"
                                title={x.description}
                                className="sub-card-details"
                                extra={roleImageView(x.role, x.created_by)}
                              >
                                <p>
                                  <Text italic>
                                    {x.data &&
                                      !(
                                        Object.keys(x.data).length === 0 &&
                                        x.data.constructor === Object
                                      ) &&
                                      x.data.note}
                                  </Text>
                                </p>
                                {stateChangeArr && stateChangeArr.length > 0 && (
                                  <div className="sub-card-content">
                                    {readableStatusView(stateChangeArr[0])}
                                    <ArrowRightOutlined />
                                    {readableStatusView(stateChangeArr[1])}
                                  </div>
                                )}
                                <p>{moment(x.created_at).format('lll')}</p>
                              </Card>
                            );
                          })}
                        </Card>
                      </Col>
                    </Row>
                  </Content>
                </div>
              </>
            )}
          </div>
        ) : (
            <div>
            <div style={isTabletOrMobile ? { marginTop: 40 } : {}}>
                {origin === UNAUTHPASSENGER || origin === UNAUTHVENDOR ?
                    <div className={isTabletOrMobile ? 'unAuthHeadMobile' : 'unAuthHeadDesktop'}>
                        <img
                            src={LogoSrc}
                            alt="SpicyWagonLogo"
                            className={isTabletOrMobile ? "logoMobileHeader" : "logoDesktopHeader"}
                        />
                        <Title style={{ textAlign: 'center' }} level={4}>{Constants.ORDERS_DETAILS}</Title>
                        {!isTabletOrMobile && <div>{Constants.IRCTC_AUTHORISED}</div>}
                    </div> :
                    <div className={isTabletOrMobile ? 'unAuthHeadMobile' : 'unAuthHeadDesktop'}>
                        <Title style={{ textAlign: 'center', flex: 3 }} level={4}>{Constants.ORDERS_DETAILS}</Title>
                    </div>
                }
            </div>
            {addEventModal}
            <div>
                {isLoading === true && <Spin size="large" className="spinner" />}
            </div>
            { Object.keys(data.details).length === 0 ? <Spin />:<TimeLine data={data} currentStep={currentStep} origin={origin ? origin : null} fetchOrderDetails={fetchOrderDetails} showPromiseConfirm={showPromiseConfirm} />}
            {
                data.details.data &&
                <>
                <div
                  className={
                    isTabletOrMobile ? 'mobile-container' : 'containerDesktop'
                  }
                >
                  <Content
                    className={
                      isTabletOrMobile
                        ? 'order-summary-wrapper-mobile'
                        : 'order-summary-wrapper'
                    }
                  >
                    <div
                      className={
                        isTabletOrMobile
                          ? 'order-status-wrapper-mobile'
                          : 'order-status-wrapper'
                      }
                    >
                      <h2 className="order-id">
                        {Constants.ORDER_ID} {data.details.order_id}
                      </h2>
                      <h1 className="order-status">
                        {data.details.status.replace(/_/g, '  ')}
                      </h1>
                    </div>
                    <div
                      className={
                        isTabletOrMobile
                          ? 'order-details-mobile'
                          : 'order-details'
                      }
                    >
                      <div className="restaurant-details-wrapper">
                        <h1 className="detail-title">
                          {Constants.RESTAURANT}
                        </h1>
                        <span className="orderDetailsText">
                          {Constants.NAME}:{' '}
                          {data.details.data.aggregatorDetails.name}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.OUTLET_NAME}{' '}
                          {data.details.data.aggregatorDetails.outletName}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.OUTLET_ID}{' '}
                          {data.details.data.aggregatorDetails.outletId}
                        </span>
                      </div>
                      <div
                        className={
                          isTabletOrMobile
                            ? 'customer-detail-wrapper-mobile'
                            : 'customer-detail-wrapper'
                        }
                      >
                        <h1 className="detail-title">
                          {Constants.CUSTOMER_DETAILS}
                        </h1>
                        <span className="orderDetailsText">
                          {Constants.NAME}:{' '}
                          {data.details.data.customerDetails.customerName}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.MOBILE}:{' '}
                          <b>{data.details.data.customerDetails.mobile}</b>
                        </span>
                        <span className="orderDetailsText">
                          {Constants.ALTERNATE_MOBILE}{' '}
                          {data.details.data.customerDetails.alternateMobile}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.EMAIL}{' '}
                          {data.details.data.customerDetails.email}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.PNR}{' '}
                          <b>{data.details.data.deliveryDetails.maskedPnr}</b>
                        </span>
                        <span className="orderDetailsText">
                          {Constants.BERTH}{' '}
                          <b>
                            {data.details.data.deliveryDetails.coach} -{' '}
                            {data.details.data.deliveryDetails.berth}
                          </b>
                        </span>
                        <span className="orderDetailsText">
                          {Constants.STATION}{' '}
                          <b>
                            {data.details.data.deliveryDetails.station} (
                            {data.details.data.deliveryDetails.stationCode})
                          </b>
                        </span>
                        <span className="orderDetailsText">
                          {Constants.TRAIN}{' '}
                          <b>
                            {data.details.data.deliveryDetails.trainNo} -{' '}
                            {data.details.data.deliveryDetails.trainName}
                          </b>
                        </span>
                        <span className="orderDetailsText">
                          {Constants.ETA} {moment(data.details.eta).format('LLL')}
                        </span>
                        <span className="orderDetailsText">
                          {Constants.ETD} {moment(data.details.etd).format('LLL')}
                        </span>
                      </div>
                    </div>
                    <div>
                      <Table
                        columns={columns}
                        dataSource={data?.details?.data?.orderItems}
                        size="small"
                        pagination={false}
                        bordered
                        className="table"
                      />
                      <Collapse defaultActiveKey={['1']}>
                        <Panel
                          header={
                            <span className="headerStyle">
                              {Constants.PRICE_BREAK_UP}
                            </span>
                          }
                          key="1"
                        >
                          <Table
                            columns={columnsForPriceBreakUp}
                            dataSource={dataForPriceTable}
                            size="small"
                            pagination={false}
                            bordered
                            className="table"
                          />
                        </Panel>
                      </Collapse>
                      &nbsp;
                      <div className="payment-type-wrapper">
                        <h1>
                          <span>{Constants.PAYMENT_TYPE}</span>
                          <span className="payment-type">
                            {data.details.data.paymentType.replace(/_/g, '  ')}
                          </span>
                        </h1>
                      </div>
                    </div>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                          title={<b>{Constants.DELIVERY_BOY_DETAILS}</b>}
                          className="card-details"
                          extra={
                            <Button
                              type="primary"
                              ghost
                              disabled={data.details.delivery_jobs.length > 0}
                            >
                              {Constants.ASSIGN_BTN}
                            </Button>
                          }
                        >
                          {data.details.delivery_jobs.length > 0 ? (
                            data.details.delivery_jobs.map((x) => {
                              return (
                                <Fragment>
                                  <p className={isTabletOrMobile && 'para'}>
                                    {' '}
                                    {Constants.ID} {x.id}, {Constants.NAME}:
                                    {x.delivery_executive.name}
                                  </p>
                                  <p className={isTabletOrMobile && 'para'}>
                                    {Constants.MOBILE} :{' '}
                                    {x.delivery_executive.phone_number}
                                  </p>
                                </Fragment>
                              );
                            })
                          ) : (
                            <p className={isTabletOrMobile && 'para'}>
                              {Constants.NO_DELIVERY_EXECUTIVE_ASSIGNED}
                            </p>
                          )}
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                          title={<b>{Constants.EVENTS}</b>}
                          // extra={
                          //   <Button type="primary" ghost onClick={showEventModal}>
                          //     {Constants.ADD_EVENT_BTN}
                          //   </Button>
                          // }
                          className="card-details"
                        >
                          {data.details.events.map((x) => {
                            let stateChangeArr =
                              x.data &&
                              x.data.state_change &&
                              x.data.state_change.match(/ORDER_\w*/g);
                            return (
                              <Card
                                size="small"
                                title={x.description}
                                className="sub-card-details"
                                extra={roleImageView(x.role, x.created_by)}
                              >
                                <p>
                                  <Text italic>
                                    {x.data &&
                                      !(
                                        Object.keys(x.data).length === 0 &&
                                        x.data.constructor === Object
                                      ) &&
                                      x.data.note}
                                  </Text>
                                </p>
                                {stateChangeArr && stateChangeArr.length > 0 && (
                                  <div className="sub-card-content">
                                    {readableStatusView(stateChangeArr[0])}
                                    <ArrowRightOutlined />
                                    {readableStatusView(stateChangeArr[1])}
                                  </div>
                                )}
                                <p>{moment(x.created_at).format('lll')}</p>
                              </Card>
                            );
                          })}
                        </Card>
                      </Col>
                    </Row>
                  </Content>
                </div>
              </>
            }
        </div>
        )}
      </>
    )
}

export default OrderDetails;