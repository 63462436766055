import React from "react";
import { Table, Descriptions, Typography, Image } from "antd";
import "./OutletDetailsScreen.css";
import { Constants } from "../../config/constant";

const { Title } = Typography;

const OutletDetailsScreen = ({ outletData, columns }) => {
  const descriptionItems = [
    { label: "Id", value: outletData.id },
    { label: "Name", value: outletData.name },
    { label: "Outlet Id", value: outletData.outlet_id },
    { label: "Address", value: outletData.address },
    { label: "Station Code", value: outletData.station_code },
    { label: "City", value: outletData.city },
    { label: "State", value: outletData.state },
    { label: "Email", value: outletData.email },
    { label: "Is Active", value: outletData.is_active ? "Yes" : "No" },
    { label: "Order Timing", value: outletData.order_timing },
    { label: "Opening Time", value: outletData.openingTime },
    { label: "Closing Time", value: outletData.closingTime },
    { label: "Longitude", value: outletData.longitude },
    { label: "Latitude", value: outletData.latitude },
    { label: "Company Name", value: outletData.companyName },
    { label: "Delivered By", value: outletData.delivered_by },
    { label: "Delivery Cost", value: outletData.deliveryCost },
    { label: "Details", value: outletData.details },
    { label: "FssaiNo", value: outletData.fssaiNo },
    { label: "Fssai Valid Upto", value: outletData.fssaiValidUpto },
    { label: "GST No", value: outletData.gstNo },
    { label: "Min Order Amount", value: outletData.minOrderAmount },
    { label: "Mobile", value: outletData.mobile },
    { label: "Prepaid", value: outletData.prepaid },
    { label: "Vendor Pan No.", value: outletData.vendorPanNumber },
    { label: "Featurd", value: outletData.featured ? "Yes" : "No" },
    { label: "Number of ratings", value: outletData.n_rating },
    { label: "Average Rating", value: outletData.average_rating },
  ];
  return (
    <div style={{marginTop:40}}>
       <Descriptions title="Outlet Details" className="descrip-web">  
       </Descriptions>
            <div className="img-logo">
          <Image width={226} src={outletData.logoImage} />
          </div>
      <Descriptions className="ant-descriptions-outletdetails">
        {descriptionItems.map(({ label, value }) => (
          <Descriptions.Item key={label} label={label}>
            {value}
          </Descriptions.Item>
        ))}   
      </Descriptions>
      <div className="vendor-asso">
        <Title level={5}>{Constants.VENDORS_ASSOCIATED} &nbsp;</Title>
        <Table      
          style={{ border: "groove" }}
          size="small"
          columns={columns}
          pagination={false}
          dataSource={outletData.vendors?.map((x) => {
            x.isActiveDisplay = x.is_active ? "Yes" : "No";
            return x;
          })}
        />
      </div>
    </div>
  );
};

export default OutletDetailsScreen;
