import React from 'react';
import { Tabs } from 'antd';
import './login.css'
import LoginPassword from './loginPassword';
import LoginOTP from './loginOTP';
import { useMediaQuery } from 'react-responsive';
import { LogoFinalSrc, IrctcLogo} from "../assets/images/index";
import { Constants } from '../config/constant';

const { TabPane } = Tabs;

const Login = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const callback = (key) => {
    }

    return (
        <div className="default" >
               <div className={isTabletOrMobile ?  'login-parentmobile':'login-parent'}> 
                    <img className={isTabletOrMobile ? 'logoMobile' : 'logoDesktoplogin'} src={LogoFinalSrc} alt={Constants.LOADING} />
                    <p className={isTabletOrMobile ?'text-restmobile':'text-rest'}>{Constants.SPICY_VAGON_RESTAURANT}</p>
                </div>
            <Tabs defaultActiveKey="1" onChange={callback} centered>         
                <TabPane tab={Constants.PASSWORD_LOGIN} key="1">
                    <LoginPassword />
                </TabPane>
                <TabPane tab={Constants.OTP_LOGIN} key="2">
                    <LoginOTP />
                </TabPane>
            
            </Tabs>
        </div>
    )
}

export default Login;