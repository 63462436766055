import React, { useState } from 'react';
import { Card, Button, Select } from 'antd';
import * as moment from 'moment';
import './selectOutlet.css'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { logout as localStorageLogOut } from '../../utility/apiServices'
import { logout } from '../../login/loginSlice'
import { saveVendorOutletId } from '../../utility/apiServices';
import { outletSelected } from '../../login/loginSlice';
import { LogoSrc } from "../../assets/images/index";
import { useMediaQuery } from 'react-responsive'
import { Constants } from '../../config/constant';
const { Option } = Select;

const SelectOutlet = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)'})
  const { SELECT_OUTLET } = Constants;

  const dispatch = useDispatch()
  const history = useHistory();

  const [vendor, setVendor] = useState(null)

  const roles = useSelector((state) => state.login.roles)



  const logMeOut = () => {
    dispatch(logout())
    localStorageLogOut()
  }

  const handleChange = (value) => {
    setVendor(value)
  }

  const onVendorSelect = () => {
    
    let vendorOutlet = roles.length > 0 && roles[0].vendors.find(y => y.outlet.outlet_id === vendor)
    dispatch(outletSelected({ outlet: vendorOutlet["outlet"], vendorOutletId: vendor }))
    saveVendorOutletId(vendor)
    return history.push({
      pathname: `/orders/date/${moment(new Date()).format('YYYY-MM-DD')}`,
      state: { selectedTab: 'orders' }
    });
  }

  return (
    <div className='wrapper'>
    <div className='logo-src'> 
    <img className='logo' src={LogoSrc} alt={Constants.LOADING} />
    </div>
    <div className={isTabletOrMobile ? "containerMobile" : "container"}>
      <div className={isTabletOrMobile ? "outlet-wrapper-mobile-card" : "outlet-wrapper"}>
        <Card title={Constants.PLEASE_SELECT_OUTLET} bordered={false} className={!isTabletOrMobile && "outletCardDesktop"}>
          <Select value={vendor} onChange={handleChange} placeholder = {SELECT_OUTLET} className={isTabletOrMobile ? "selectWidthMobile" : "selectWidthDesktop" }>
            {
              roles.length > 0 && roles[0].vendors.map(x => {
                return <Option value={x.outlet.outlet_id}>{x.outlet.name}</Option>
              })
            }
          </Select>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={onVendorSelect}
          >
            {Constants.GO}
          </Button>
        </Card>
        <Button style={{ marginTop: 10 }} onClick={logMeOut}>
          {Constants.LOGOUT}
        </Button>
      </div>
    </div>
    </div>
  )
}

export default SelectOutlet;