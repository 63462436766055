import config from "./config";

export const BaseURL = config.backendServer;

const apiEndpoints = {
  // Login Stack
  // Login With Password
  getToken: `${BaseURL}common/login-with-password/`,
  // Send Login OTP
  getOtp: `${BaseURL}common/send-login-otp/`,
  // Verifying the OTP
  verifyOtp: `${BaseURL}common/verify-otp/`,
  // Password Reset OTP
  sendResetOtp: `${BaseURL}common/send-password-reset-otp/`,
  // Verifying Reset Password OTP
  verifyResetOtp: `${BaseURL}common/verify-otp-password-reset/`,
  // Updating the Password
  updatePassword: `${BaseURL}common/update-password/`,

  // Roles call
  getRoles: `${BaseURL}common/roles/`,

  // Orders by date
  getOrdersDate: (id, date) =>
    `${BaseURL}dashboard/vendor/${id}/orders/${date}/`,
  getOrdersDetails: (id, orderId) =>
    `${BaseURL}dashboard/vendor/${id}/order/${orderId}/details`,
  confirmOrder: (id, orderId) =>
    `${BaseURL}dashboard/vendor/${id}/order/${orderId}/confirm`,

  // getOrdersDataSince: (id, date, sinceId) => `${BaseURL}dashboard/vendor/${id}/orders/${date}/?since_id/${sinceId}`,

  eventsCall: (id, orderId) =>
    `${BaseURL}dashboard/vendor/${id}/order/${orderId}/events`,

  // Devies
  devicesRegistration: `${BaseURL}devices`,

  // Change status end points
  changeStatus: (outletId, orderId, status) =>
    `${BaseURL}dashboard/vendor/${outletId}/order/${orderId}/change-status-to/${status}`,

  // Unauthenticated End points

  // get order details for unauthenticated users
  getOrderDetailsUnAuth: (pnr, orderId) =>
    `${BaseURL}open-api/order/${pnr}/${orderId}/details`,
  // order confirmation from Vendor
  vendorConfirmUnAuth: (pnr, orderId) =>
    `${BaseURL}open-api/vendor-order-confirm/${pnr}/${orderId}/`,
  // order confirmation from Passenger side this should be only active if the user has accepted it, i.e. order.confirmed_by_user=null
  passengerConfirmUnAuth: (pnr, orderId) =>
    `${BaseURL}open-api/passenger-order-confirm/${pnr}/${orderId}/`,

  // outlets Info
  getOutletDetails: (outletId) =>
    `${BaseURL}dashboard/outlet/${outletId}/details`,
  // Get Menu Items
  getMenuItems: (outletId) =>
    `${BaseURL}dashboard/menu-item/${outletId}/list/`,
  // Updating an Image for a menu item
  updateImageForMenu: (id) =>
    `${BaseURL}dashboard/menu-item/${id}/update-image`,
  menuItemOperations: (id) => `${BaseURL}dashboard/menu-item/${id}`,
  // GetFeedbacks
  getFeedbacks: (outletId, startDate, endDate) => 
  `${BaseURL}dashboard/${outletId}/feedback-stats/?start_date=${startDate}&end_date=${endDate}`,
};

export default apiEndpoints;
