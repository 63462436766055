import React from 'react';
import { Collapse, Typography, Select, Alert, message, Card, Switch } from 'antd';
import './settings.css'
import { useSelector, useDispatch } from 'react-redux'
import { changeNotificationState, changeRefreshTime } from '../../features/settings/settingsSlice'
import { Constants,adminContactDetails } from '../../config/constant';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

const Settings = () => {

    const {refreshTime,isActiveNotification} = useSelector((state) => state.settings)
    const dispatch = useDispatch()
    const { SETTING_MESSAGE, SETTING_DESCRIPTION } = Constants

    const handleToggle = () => {    
        dispatch(changeNotificationState(!isActiveNotification))
    };
    

    function callback(key) {
    }

    function handleChange(value) {
        dispatch(changeRefreshTime(value))
        const valueInMins = parseInt(value)/(60 * 1000)
        const lastIndex = Constants.REFRESH_TIME_CHANGED.lastIndexOf(" ")
        message.success(`${Constants.REFRESH_TIME_CHANGED.substring(0,lastIndex)} ${valueInMins} ${Constants.REFRESH_TIME_CHANGED.substring(lastIndex)}`);
    }

    function openInNewTab (url){
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
      

    return (
        <div>
            <Collapse onChange={callback}>
                <Panel header={Constants.REFRESH_TIMING} key="1">
                    <div className="selectDiv">
                        <Text>{Constants.PLEASE_SELECT_THE_TIME_FOR_REFRESH}:  </Text>
                        <Select value={refreshTime.toString()} style={{ width: 120 }} onChange={handleChange}>
                            <Option value="60000">1 min</Option>
                            <Option value="120000">2 mins</Option>
                            <Option value="180000">3 mins</Option>
                            <Option value="240000">4 mins</Option>
                            <Option value="300000">5 mins</Option>
                        </Select>
                    </div>
                    <Alert
                        message={SETTING_MESSAGE}
                        description={SETTING_DESCRIPTION}
                        type="info"
                        showIcon
                    />
                </Panel>
                <Panel header={Constants.NOTIFICATION} key="2">
                   <Card><span>{Constants.NOTIFICATION}
                    </span> <Switch checked={isActiveNotification} onChange={handleToggle}  className='itemLength' /></Card>
                </Panel>
                <Panel header={Constants.HELP} key="3">
                    <div>
                            <Card value={Constants.EMAIL} onClick={() => openInNewTab(Constants.GMAIL)}>{adminContactDetails.EMAIL_US}</Card>
                            <Card value={Constants.CALL}>{adminContactDetails.CALL_AT}</Card>
                            <Card value={Constants.HELP_WHATSAPP} onClick={() => openInNewTab(Constants.WHATSAPP)}>{adminContactDetails.CONNECT_ON_WHATSAPP}</Card>
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}

export default Settings