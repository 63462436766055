import React, { useEffect, useState, Fragment } from 'react';
import {DatePicker, Typography, Button, Spin, message, Modal,
    Alert, notification
} from 'antd';
import * as moment from 'moment';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import LocalStorageVariables from '../../config/localStorage';
import {
    ReloadOutlined,
    CheckCircleTwoTone
} from '@ant-design/icons';
import './ordersByDate.css'
import { useHistory, useParams } from "react-router-dom";
import { useSpeechSynthesis } from 'react-speech-kit';
import { useMediaQuery } from 'react-responsive'
import { Confirmed,NotConfirmed, ColorRestaurant, RESTAURANT, COD, PREPAID } from '../../assets/images/index'
import { Constants } from '../../config/constant';
import OrderByTrain from '../orderByTrainInfo/OrderByTrain';

const { Title } = Typography;
const { confirm } = Modal;

const OrdersByDate = (props) => {

    const { speak } = useSpeechSynthesis();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { REFRESHOFFER, NEWORDER, NEW_ORDER_DESCRIPTION } = Constants

    const history = useHistory();
    const params = useParams();

    const authToken = useSelector((state) => state.login.authToken)
   

    const {refreshTime,isActiveNotification} = useSelector((state) => state.settings)

    useEffect(() => {
        fetchOrdersByDate(moment(params.date).format('YYYY-MM-DD'))
        //eslint-disable-next-line
    }, []);

    const [latestIdtoCheckWith, setLatestIdtoCheckWith] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            fetchOrdersSinceLatest(latestIdtoCheckWith)
        }, refreshTime);
        return () => clearInterval(interval);
        //eslint-disable-next-line
    }, [latestIdtoCheckWith]);

    const [data, setData] = useState({
        dateSelected: moment(moment(params.date).format('YYYY-MM-DD')),
        orders: [],
        previous: null,
        next: null,
        count: 0,
        latestId: 0,
    })

    const [newOrdersCount, setNewOrdersCount] = useState(0)
    const [isLoding, setIsLoding] = useState(false)
    const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);

    const fetchOrdersByDate = (date, page) => {
        setIsLoding(true)
        let url = Apis.getOrdersDate(outletId, moment(date).format('YYYY-MM-DD'));
        if (page) {
            url = url + '?page=' + page
        }
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];

                const ords = resData.results.map(x => {
                    try {
                        x.key = x.id
                        x.name = <>{x.data.customerDetails.customerName}
                            <br />
                            {x.customer_mobile} </>;
                        x.berth = <>{x.data.deliveryDetails.coach} - {x.data.deliveryDetails.berth}
                            <br />
                            {x.data.deliveryDetails.trainName} - {x.data.deliveryDetails.trainNo}
                            <br />
                            {moment(x.eta).format('MM-DD-YYYY HH:mm')}
                        </>;
                        x.station = x.data.deliveryDetails.station;
                        x.confirmButton = { order_id: x.data.orderId, confirmDisable: (x.status === 'ORDER_PLACED' ? false : true) };
                        x.trainDetails = x.data.deliveryDetails.trainNo + '-' +
                            x.data.deliveryDetails.trainName;
                        x.amount = <> {x.payment_type === "CASH_ON_DELIVERY" ?
                            <Fragment>
                                <img src={COD} alt="COD" className="tablelogo" />
                                <div>{Constants.COD}</div>
                            </Fragment> :
                            <Fragment>
                                <img src={PREPAID} alt="Prepaid" className="tablelogo" />
                                <div>{Constants.PREPAID}</div>
                            </Fragment>}
                                &nbsp;
                                &#8377;{x.data.priceDetails.totalAmount}
                        </>;
                        x.bookingDate = x.data.bookingDate
                        x.deliveryDate = x.data.deliveryDate;
                        x.etaformatted = moment(x.eta).format('MM-DD-YYYY HH:mm')
                        x.items = x.data.orderItems.map(y => {
                            return <> {y.itemName} ({y.quantity}) <br /> </>
                        })
                        x.passenger_status = x.confirmed_by_user ?
                            <Fragment>
                                <img src={Confirmed} alt="Confirmed" className="tablelogo" />
                                <div>{Constants.CONFIRMED}</div>
                            </Fragment>
                            :
                            <Fragment>
                                <img src={NotConfirmed} alt="Not Confirmed" className="tablelogo" />
                                <div>{Constants.NOT_CONFIRMED}</div>
                            </Fragment>
                        x.vendor_status = x.status !== 'ORDER_PLACED' ?
                            <Fragment>
                                <img src={ColorRestaurant} alt="Confirmed" className="tablelogo" />
                                <div>{Constants.CONFIRMED}</div>
                            </Fragment> :
                            <Fragment>
                                <img src={RESTAURANT} alt="Not Confirmed" className="tablelogo" />
                                <div>{Constants.NOT_CONFIRMED}</div>
                            </Fragment>
                        return x
                    } catch (e) {
                        return x
                    }
                })
                let tempLatestId = data?.latestId
                if (resData?.results?.length > 0) {
                    tempLatestId = resData?.results[0].id
                }
                setData({
                    ...data,
                    orders: ords,
                    previous: resData.previous,
                    next: resData.next,
                    count: resData.count,
                    dateSelected: date,
                    latestId: tempLatestId > data.latestId ? tempLatestId : data.latestId,
                })
                setNewOrdersCount(0)
                setIsLoding(false)
                let tempLatIdToCheck = tempLatestId > data.latestId ? tempLatestId : data.latestId
                setLatestIdtoCheckWith(tempLatIdToCheck)
            })
            .catch(err => {
                message.error(Constants.CANT_CONFIRM_ORDER, err);
                setIsLoding(false)
            });
    }

    const fetchOrdersSinceLatest = (sinceId) => {
        let url = Apis.getOrdersDate(outletId, moment(params.date).format('YYYY-MM-DD'));
        url = url + `?since_id=${sinceId}`
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                if (res.data.count > newOrdersCount) {
                    setNewOrdersCount(res.data.count)
                  if(isActiveNotification){
                    const newCount = res.data.count - newOrdersCount  
                    openNotification(newCount)
                    speak({text: `${newCount} new order recieved`})
                   }
                }
                //  else {
                //     setNewOrdersCount(0)
                // }
            })
    }

    const onOrderClick = (e, id) => {
        return history.push({
            pathname: `/order/id/${id}/date/${moment(params.date).format('YYYY-MM-DD')}`,
            state: { selectedTab: 'orders' }
        });
    }

    const showPromiseConfirm = (e, id) => {
        confirm({
            title: 'Do you want to confirm the order?',
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            content: Constants.ORDER_CONFIRM_BY_OPERATOR,
            onOk() {
               onConfirmClick(e, id);           
               return true
            },
            onCancel() { 
                return false
            },
        });
    }
    //TODO
    // const changeStatusToCancel = (id,note) => {
    //     const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);
    //     let url = Apis.changeStatus(outletId,id, Constants.ORDER_CANCELLED);
       
    //     if (note) {
    //         url = url + `?note=${note}`
    //     }
    //     HttpUtils.Get(url,
    //         { Authorization: 'Token ' + authToken })
    //         .then(res => {
    //             const resData = JSON.parse(JSON.stringify(res))['data'];
    //             message.success(resData.message);
    //             console.log("===>>",url,resData)
    //              return resData
    //         })
    //         .catch(err => {
    //             message.error(`Can't Confirm Order`);
    //             return err
    //         });
    // }

    const onConfirmClick = (id) => {
        const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);
        console.log("🚀 ~ file: ordersByDate.js:225 ~ onConfirmClick ~ outletId:", outletId)
        let url = Apis.confirmOrder(outletId, id);
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                message.success('Order Confirmed');
                fetchOrdersByDate(data.dateSelected)
                return resData

            })
            .catch(err => {
                console.log("🚀 ~ file: ordersByDate.js:236 ~ onConfirmClick ~ err:", err)
                message.error(`Can't Confirm Order`);
                return err
            });
    }
    
    const onChange = (date) => {
        setData({
            ...data,
            dateSelected: date
        })
        fetchOrdersByDate(date)
        return history.push({
            pathname: `/orders/date/${moment(date).format('YYYY-MM-DD')}`,
            state: { selectedTab: 'orders' }
        });
    }

    const openNotification = (data) => {
        notification.open({
            message: Constants.NEWORDER,
            description:
            `${data} ${Constants.NEW_ORDER_DESCRIPTION}`,
        });
    };
/*
    TODO
    const columns = [
        // {refreshTime
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     fixed: 'left',
        // },
        {
            title: '',
            dataIndex: 'vendor_status',
            key: 'vendor_status',
            align: 'center',
        },
        {
            title: 'IRCTC ORD ID',
            dataIndex: 'order_id',
            key: 'order_id',
            render: id => <Button type="link" block onClick={(event) => onOrderClick(event, id)}>
                {id}
            </Button>,
            fixed: 'left',
        },
        {
            title: '',
            dataIndex: 'passenger_status',
            key: 'passenger_status',
            align: 'center',
        },
        {
            title: 'ORDER DETAILS',
            dataIndex: 'items',
            key: 'items',
        },
        {
            title: 'PASS. DETAILS',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'JOURNEY DETAILS',
            dataIndex: 'berth',
            key: 'berth',
        },
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center'
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'CONFIRMATION',
            dataIndex: 'confirmButton',
            key: 'confirmButton',
            render: (id) => <Button type="link" disabled={id.confirmDisable} block onClick={(event) => showPromiseConfirm(event, id.order_id)}>
                Confirm
            </Button>,
            fixed: 'right',
        },
    ];

    const columnsMobile = [
        {
            title: 'IRCTC ORD ID',
            dataIndex: 'order_id',
            key: 'order_id',
            render: id => <Button type="link" block onClick={(event) => onOrderClick(event, id)}>
                {id}
            </Button>,
            fixed: 'left',
        },
        {
            title: 'CONFIRMATION',
            dataIndex: 'confirmButton',
            key: 'confirmButton',
            render: (id) => <Button type="link" disabled={id.confirmDisable} block onClick={(event) => showPromiseConfirm(event, id.order_id)}>
                Confirm
            </Button>,
            // fixed: 'left',
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'PHONE',
            dataIndex: 'customer_mobile',
            key: 'customer_mobile',
        },
        {
            title: 'TRAIN DETAILS',
            dataIndex: 'trainDetails',
            key: 'trainDetails',
        },
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center'
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
        },
    ];
    */


    return (
        <div style={isTabletOrMobile ? { marginTop: 40 } : {}}>
            <Title style={{ textAlign: 'center' }} level={4}>{Constants.ORDER_BY_DATE}</Title>
           
            <>
            <div className="header">
                <div>
                    <Title level={5}>{Constants.SELECT_DATE}</Title>
                    <DatePicker
                        onChange={onChange}
                        defaultValue={data.dateSelected}
                    />
                </div>
     

                {newOrdersCount > 0 && 
                    <Alert
                        message={REFRESHOFFER}
                        type="success"
                        showIcon
                        action={
                            <Button
                                onClick={() => { fetchOrdersByDate(data.dateSelected) }}
                                type="primary"
                                size="small"
                                shape="circle"
                                icon={<ReloadOutlined />}
                            />

                        }
                    />
                }
            </div>
            
            {isLoding === true && <Spin size="large" className="spinner" />}
            
            <OrderByTrain  {...{
                orders:data.orders,
                onOrderClick,
                onConfirmClick
            }} />
            {/* TODO */}
            {/* {
                isTabletOrMobile ?
                    <>
                        <Table
                            columns={columnsMobile}
                            dataSource={data.orders}
                            className="table"
                            size="small"
                            pagination={{
                                pageSize: 20,
                                total: data.count,
                                onChange: (pgNo) => fetchOrdersByDate(moment(params.date).format('YYYY-MM-DD'), pgNo)
                            }}
                        />
                    </>
                    :
                    <Table
                        columns={columns}
                        dataSource={data.orders}
                        className="table"
                        size="small"
                        pagination={{
                            pageSize: 20,
                            total: data.count,
                            onChange: (pgNo) => fetchOrdersByDate(moment(params.date).format('YYYY-MM-DD'), pgNo)
                        }}
                    />
            } */}
            </>
        </div>
    )
}

export default OrdersByDate;