import React, { useState, useEffect } from 'react';
import { Steps, Modal, Select, Input, message, Button } from 'antd';
import * as moment from 'moment';
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import { useSelector } from 'react-redux'
import '../timeLine/timeLine.css'
import { OrderPlaced, Cooking, FoodDelivery, OrderCancel, PreparedFinal, PreparedInitial, CookingInitial, DeliveryInitial, DeliveryFinal, FoodDeliveryInitial, PartiallyDelivered, UNDELIVERED } from '../../assets/images/index'
import { Constants } from '../../config/constant';
import { useMediaQuery } from 'react-responsive';

const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const TimeLine = (props) => {
    const {data: {details : {data :{ orderItems}}} } = props;
    const { data:{ details:{ status}}} = props;
    const { data:{details:{outlet_id}}} = props;
    const { data:{details:{order_id}}} = props;
    const {origin} = props;
    const { data: {details:{eta}}} = props;
    const { currentStep } = props;

    const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [statusInDialog, setStatusInDialog] = useState(null)
    const [noteForStatus, setNoteForStatus] = useState(null)
    const [noteForCancel, setNoteForCancel] = useState(null)
    const [itemQuantity, setItemQuantity] = useState(orderItems);
    const [itemData, setItemData] = useState([]);
    const { SELECT_STATUS,ORDER_CANCELLED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED, ORDER_PREPARING, ORDER_PLACED, 
            ORDER_PREPARED,ORDER_OUT_FOR_DELIVERY, ORDER_DELIVERED,UNAUTHVENDOR, UNAUTHPASSENGER} = Constants
    // const { isTabletOrMobile } = props

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 786px)' });

    const authToken = useSelector((state) => state.login.authToken)
    const presentStatus = status

    useEffect(()=>{
        const tempdata = itemQuantity.map((id)=>{
            return { 
                id: id.itemId,
                quantity: id.quantity,
            }
            });
            setItemData(tempdata)
    },[])
    
    const handleAddItem = (id) => {
       const initialValue = itemData.find((element)=> element.id === id.itemId)
       setItemQuantity((prev)=>{
        return prev.map((ele)=>{
            if((ele.itemId === id.itemId) && ele.quantity !== initialValue.quantity){
                return {
                    ...ele,
                    quantity: ele.quantity + 1,
                }
            }
            return ele;
        })
       })

      };
      const handleSubtractItem = (id) => {
        setItemQuantity((prev)=>{
            return prev.map((ele)=>{
                if((ele.itemId === id.itemId) && ele.quantity > 0){
                    return {
                        ...ele,
                        quantity: ele.quantity - 1,
                    }
                }
                return ele;
            })
           })
      };

    const changeStatus = (note, iscancel) => {
        let url = Apis.changeStatus(outlet_id, order_id, statusInDialog);
        if (iscancel) {
            url = Apis.changeStatus(outlet_id, order_id, ORDER_CANCELLED);
        }
        if (note) {
            url = url + `?note=${note}`
        }
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                message.success(resData.message);
                setNoteForStatus(null)
                setStatusInDialog(null)
                props.fetchOrderDetails()
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    const onChangeStatus = () => {
        setIsStatusModalVisible(true);
    };

    const handleStatusOk = () => {
            changeStatus()
            setIsStatusModalVisible(false);
    };

    const handleStatusCancel = () => {
        setIsStatusModalVisible(false);
        setNoteForStatus(null)
        setStatusInDialog(null)
    };

    const handleStatusChange = (value) => {
        setStatusInDialog(value)
    }

    const onNoteForStatusChange = (e) => {
        setNoteForStatus(e.target.value)
    }

    // const onCancelOrder = () => {
    //     setIsCancelModalVisible(true);
    // }

    const handleCancelOk = () => {
        if (!noteForCancel) {
            message.error(`Need to add note for Cancellation of an order`);
        } else {
            changeStatus(noteForCancel, true)
            setIsCancelModalVisible(false)
        }
    }

    const handleCancelCancel = () => {
        setIsCancelModalVisible(false)
        setNoteForCancel(null)
    }

    const onNoteForCancelOrder = (e) => {
        setNoteForCancel(e.target.value)
    }

    const modalEle = <Modal
        title="Change Order Status"
        visible={isStatusModalVisible}
        onOk={handleStatusOk}
        onCancel={handleStatusCancel}
    >
        <p>{Constants.SELECT_THE_CURRENT_STATE}: &nbsp;&nbsp;
            <Select style={{ width: 180 }} onChange={handleStatusChange} value={statusInDialog} placeholder= {SELECT_STATUS}>
                <Option value={ ORDER_PREPARING} disabled={ status !== ORDER_PLACED}>{Constants.PREPARING}</Option>
                <Option
                    value= {ORDER_PREPARED}
                    disabled={[ORDER_PREPARED, ORDER_OUT_FOR_DELIVERY, ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED].includes(presentStatus)}
                >
                    {Constants.PREPARED}
                </Option>
                <Option
                    value={ ORDER_OUT_FOR_DELIVERY}
                    disabled={[ORDER_OUT_FOR_DELIVERY, ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED].includes(presentStatus)}
                >
                    
                    {Constants.OUT_FOR_DELIVERY}
                </Option>
                <Option
                    value={ORDER_DELIVERED}
                    disabled={[ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED].includes(presentStatus)}
                >
                    {Constants.DELIVERED}
                </Option>
                <Option
                    value={ORDER_PARTIALLY_DELIVERED}
                    disabled={[ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED].includes(presentStatus)}
                >
                    {Constants.PARTIALLY_DELIVERED}
                </Option>
                <Option
                    value={ORDER_UNDELIVERED}
                    disabled={[ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED, ORDER_UNDELIVERED].includes(presentStatus)}
                >
                    {Constants.UNDELIVERED}
                </Option>
            </Select>
        </p>
        {
            (statusInDialog === Constants.ORDER_PARTIALLY_DELIVERED || statusInDialog === Constants.ORDER_UNDELIVERED) &&
            <div className='wrapper-timeline'>
              {itemQuantity.map((id) => (
                <div key={id.itemId} className='item-container'>
                  <span className='item-name'>{id.itemName} :</span>
                  <div className='quantity-container'>
                  <Button className="box-one" onClick={() => handleSubtractItem(id)} size='small'>-</Button>
                  <div className='quantity'>{id.quantity}</div>
                    <Button className='box-one' onClick={() => handleAddItem(id)} size='small'> + </Button>
                  </div>
                </div>
              ))}
          </div>
          
        }
    </Modal>

    const cancelModalEle = <Modal
        title="Cancel your Order"
        visible={isCancelModalVisible}
        onOk={handleCancelOk}
        onCancel={handleCancelCancel}
    >
        <TextArea rows={2} value={noteForCancel} onChange={onNoteForCancelOrder} placeholder="Reason for cancellation" />
    </Modal>

    return (
        <div>
            {modalEle}
            {cancelModalEle}
            {/* {!(origin === UNAUTHVENDOR || origin === UNAUTHPASSENGER) && */}
            {(!(origin === UNAUTHPASSENGER) || origin === UNAUTHVENDOR) &&
                <div className={isTabletOrMobile ? 'status-button-mobile':'statusButton' }>
                    {/* <span> Click here to Change Status --&gt; </span> */}
                    {/* <Button type="primary" shape="circle" icon={<ThunderboltOutlined />} /> */}
                    {/* <div> */}
                    {origin !== UNAUTHVENDOR &&
                    <Button
                        type="primary"
                        className='order-status-button'
                        onClick={onChangeStatus}
                        disabled={[
                            ORDER_DELIVERED, ORDER_PARTIALLY_DELIVERED,
                            ORDER_UNDELIVERED, ORDER_CANCELLED].includes(presentStatus)}
                    >
                        {Constants.CHANGE_STATUS}
                    </Button>}
                    <Button
                        type="primary" ghost
                        className='order-status-button'
                        onClick={props.showPromiseConfirm}
                        disabled={status !== ORDER_PLACED}
    
                    >
                        {Constants.MARK_CONFIRMED}
                    </Button>
                    {/* </div> */}
                </div>}
          {!isTabletOrMobile &&  <div>
                {status === ORDER_CANCELLED ?
                    <Steps progressDot current={1} style={{ marginTop: '20px', marginBottom: '20px' }} status="error" responsive>
                        <Step
                            title="Placed"
                            description={<img src={OrderPlaced} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            title="Cancelled"
                            description={<img src={OrderCancel} alt="Order Cancelled" className="tablelogo" />}
                        />
                    </Steps> :
                    <Steps progressDot current={currentStep} style={{ marginTop: '20px', marginBottom: '20px' }} responsive>
                        <Step
                            title={<img src={OrderPlaced} alt="Order Placed" className="tablelogo" />}
                            description="Placed"
                        />
                        <Step
                            description="Confirmed"
                            title={currentStep > 0 ? <img src={Cooking} alt="Order Placed" className="tablelogo" /> : <img src={CookingInitial} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            description="Prepared"
                            title={currentStep > 1 ? <img src={PreparedFinal} alt="Order Placed" className="tablelogo" /> : <img src={PreparedInitial} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            description="Out for delivery"
                            title={currentStep > 2 ? <img src={DeliveryFinal} alt="Order Placed" className="tablelogo" /> : <img src={DeliveryInitial} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            description={status === ORDER_PARTIALLY_DELIVERED ? 'Partially Delivered' :
                                status === ORDER_UNDELIVERED ? 'Undelivered' : "Delivered"}
                            subTitle={`ETA - ${moment(eta).format('LT')}`}
                            title={currentStep > 3 ? status === ORDER_PARTIALLY_DELIVERED ? <img src={PartiallyDelivered} alt="Order Delivered" className="tablelogo" /> :
                            status === ORDER_UNDELIVERED ? <img src={UNDELIVERED} alt="Order Delivered" className="tablelogo" /> : <img src={FoodDelivery} alt="Order Delivered" className="tablelogo" /> : <img src={FoodDeliveryInitial} alt="Order Delivered" className="tablelogo" />}
                        />
                    </Steps>}
            </div>}
        </div>
    )
}

export default TimeLine;