import React from 'react';
import { Form, Input, Button, Card, message, Spin } from 'antd';
import './login.css'
import { passwordLogin, autoRegister, getRoles } from '../utility/apiServices';
import { useDispatch } from 'react-redux'
import { loginSuccessful, startLoader, stopLoader, rolesSuccessful } from './loginSlice'
import { LogoFinalSrc, IrctcLogo} from "../assets/images/index";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { validationRules, Constants } from "../config/constant";

const Login = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const loading = useSelector((state) => state.login.loading)
    const { MOBILEVALIDATION, PASSWARDVALIDATION } = validationRules

    const dispatch = useDispatch()

    const onFinish = (values) => {
        dispatch(startLoader())
        passwordLogin(values)
            .then(auth_token => {
                autoRegister(auth_token, values["mobileNumber"])
                dispatch(loginSuccessful({ authToken: auth_token, mobileNumber: values["mobileNumber"] }))
                dispatch(stopLoader())
                message.success('Successfully Logged In');
                getRoles(auth_token).then(res => {
                    dispatch(rolesSuccessful({ roles: res }))
                })
            })
            .catch(err => {
                dispatch(stopLoader())
                message.error(`Cannot Log In`);
            });
    };

    const onFinishFailed = (errorInfo) => {
    };

    return (
        <>
        <div>{ loading && <Spin className="spinner" /> } </div>
        <div className={isTabletOrMobile ? 'containerDivMobile' : 'containerDiv'}>
            <div>
                <Card
                    className='loginpass-card'
                >
                    <img src={IrctcLogo} className='loginpass-irctclogo' alt={Constants.LOADING} />
                    <span className='irctc-text'>{Constants.IRCTC_PARTNER}</span>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className={isTabletOrMobile ? "loginMobile" : "login"}
                    >
                        <Form.Item
                            label="Mobile No."
                            name="mobileNumber"
                            rules={[
                                MOBILEVALIDATION
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                PASSWARDVALIDATION
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                        className={isTabletOrMobile ? 'loginpass-formitembuttonmobile':'loginpass-formitembutton'}
                        >
                             <Button
        style={style.buttonStyle}
        className={isTabletOrMobile ? "homebutton-loginmobile":"homebutton-login"}
        type="primary"
        htmlType='submit'
        block
      >
        {Constants.LOGIN}
      </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
        </>
    )
}
const style = {
    buttonStyle: {
      width: 200,
    },
  };
export default Login;