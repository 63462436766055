import React, { useEffect, useState } from "react";
import {
  message,
} from "antd";
import { useSelector } from "react-redux";
import HttpUtils from "../utility/httpUtils";
import Apis from "../config/apiEndpoints";
import * as moment from "moment";
import FeedBackScreen from "../views/feedback/FeedBackScreen";
import LocalStorageVariables from "../config/localStorage";



const FeedBackViewModel = (props) => {
  const authToken = useSelector((state) => state.login.authToken);
  const outletId = localStorage.getItem(LocalStorageVariables.vendor_outlet_id);

  // This is for details display in Feedbacks tab
  const [FeedbacksDisplay, setFeedbacksDisplay] = useState([]);

  // This is for details display in Feedbacks Aggeraged detailstab
  const [SFeedbacksDisplay, setSFeedbacksDisplay] = useState({});


  const getFeedbacks = (startDate, endDate) => {
    setIsLoding(true);
    let url = Apis.getFeedbacks(outletId, startDate, endDate);
    HttpUtils.Get(url, { Authorization: "Token " + authToken })
      .then((res) => {
        const resData = JSON.parse(JSON.stringify(res))["data"];
        setSFeedbacksDisplay(resData);
        var data = [];
        for (const feedback of resData.feedbacks) {
          if (feedback.order) {
            var items = [];
            for (const i in feedback.order.orderItems) {
              const item = feedback.order.orderItems[i];
              items.push(item.itemName + "×" + item.quantity + "\n");
            }
          }
          const temp_obj = {
            id: feedback.id,
            starRating: feedback.starRating,
            isComplaint: feedback.isComplaint,
            feedbackText: feedback.data.feedbackText,
            order_date: feedback.order_date,
            items: items,
            amountPayable: feedback.data.amountPayable,
            train_no: feedback.train_no,
          };
          data.push(temp_obj);
        }
        setFeedbacksDisplay(data);
        setIsLoding(false);
      })
      .catch((err) => {
        message.error(err.message);
        setIsLoding(false);
      });
  };


  useEffect(() => {
    getFeedbacks()
    //eslint-disable-next-line
  }, []);

  const columnsForFeedbacks = [
    {
      title: "rating id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "starRating",
      dataIndex: "starRating",
      key: "starRating",
      align: "starRating",
      sorter: (a, b) => a.starRating - b.starRating,
    },
    {
      title: "isComplaint",
      dataIndex: "isComplaint",
      key: "isComplaint",
      sorter: (a, b) => a.isComplaint - b.isComplaint,
    },
    {
      title: "feedbackText",
      dataIndex: "feedbackText",
      key: "feedbackText",
      width: 600,
    },
    {
      title: "order_date",
      dataIndex: "order_date",
      key: "order_date",
      sorter: (a, b) => moment(a.order_date) - moment(b.order_date),
    },
    {
      title: "items",
      dataIndex: "items",
      key: "items",
      render: (items, row, index) => items.map((item) => <p>{item}</p>),
    },
    {
      title: "amountPayable",
      dataIndex: "amountPayable",
      key: "amountPayable",
      sorter: (a, b) => a.amountPayable - b.amountPayable,
    },
    {
      title: "train_no",
      dataIndex: "train_no",
      key: "train_no",
      sorter: (a, b) => a.train_no - b.train_no,
    },
  ];


  const [startDate, setStartDate] = useState(
    moment(moment(new Date()).subtract(20, "days")).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(moment(new Date()).subtract(5, "days")).format("YYYY-MM-DD")
  );
  const onRangeChange = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };
  const [isLoding, setIsLoding] = useState(false);

  return (
    <FeedBackScreen
      {...{
        startDate,
        endDate,
        getFeedbacks,
        onRangeChange,
        isLoding,
        SFeedbacksDisplay,
        columnsForFeedbacks,
        FeedbacksDisplay,
      }}
    />
  );
};

export default FeedBackViewModel;
